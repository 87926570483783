type SliceOptions = {
  sliceStartSec: number;
  sliceEndSec: number;
  /**
   * The duration of the fade-in and fade-out in seconds.
   * If not provided, no fade is applied.
   * Useful for avoiding clicks when sliced audio is played in a loop.
   */
  fade?: number;
};

/**
 * Slice an audio buffer between the given start and end times (in seconds).
 */
export async function sliceAudioBuffer(
  audioContext: AudioContext,
  inputAudioBuffer: AudioBuffer,
  options: SliceOptions
): Promise<AudioBuffer> {
  const { sliceStartSec, sliceEndSec, fade } = options;

  try {
    // Compute start and end sample frames
    const sampleRate = inputAudioBuffer.sampleRate;
    const numOfChannels = inputAudioBuffer.numberOfChannels;
    const sliceStartSample = Math.floor(sliceStartSec * sampleRate);
    const sliceEndSample = Math.floor(sliceEndSec * sampleRate);
    const sliceLength = sliceEndSample - sliceStartSample;

    // Create a new AudioBuffer
    const outputAudioBuffer = audioContext.createBuffer(
      numOfChannels,
      sliceLength,
      sampleRate
    );

    // Slice the original buffer and copy the data to the new buffer
    for (let channel = 0; channel < numOfChannels; channel++) {
      const slicedChannelData = inputAudioBuffer
        .getChannelData(channel)
        .slice(sliceStartSample, sliceEndSample);

      if (fade) {
        applyFadeToChannel(slicedChannelData, sampleRate, fade);
      }

      outputAudioBuffer.copyToChannel(slicedChannelData, channel);
    }

    return outputAudioBuffer;
  } catch (error) {
    console.error("Error slicing audio blob:", error);
    throw error;
  }
}

function applyFadeToChannel(
  channelData: Float32Array,
  sampleRate: number,
  fadeLengthSec: number
) {
  const fadeSamples = Math.floor(fadeLengthSec * sampleRate);

  // Apply fade-in
  for (let i = 0; i < fadeSamples; i++) {
    const fadeFactor = i / fadeSamples;
    channelData[i] *= fadeFactor;
  }

  // Apply fade-out
  for (let i = channelData.length - fadeSamples; i < channelData.length; i++) {
    const fadeFactor = (channelData.length - i) / fadeSamples;
    channelData[i] *= fadeFactor;
  }
}
