import { APITypesV1 } from "@cur8/api-client";
import { fromAPI, ImmutableScan, Visit } from "@cur8/rich-entity";
import { BULLET_MARKER } from "lib/doctor-scribe/constants";
import { ScribeFlavor } from "lib/doctor-scribe/types";
import { useLLMResults } from "lib/doctor-scribe/useLLMResults";
import {
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useAPIClient } from "render/context/APIContext";
import { PatientName } from "render/fragments/patient/PatientName";
import SummaryEditor from "render/fragments/visit/Editors/SummaryEditor/SummaryEditor";
import { usePatient } from "render/hooks/api/usePatient";
import { useVisitStore } from "render/hooks/api/useVisitStore";
import { useFormHandle } from "render/hooks/useFormHandle";
import { useReporting } from "render/hooks/useReporting";
import { DayMonthYearDate } from "render/ui/format/DayMonthYearDate";
import { PageHeader } from "render/ui/layouts/PageHeader";
import { BackButtonClick } from "render/ui/trigger/BackButtonClick";
import { SubmitButton } from "render/ui/trigger/SubmitButton";
import { useFeedbackDialog } from "./components/FeedbackModal/useFeedbackDialog";
import { ReviewMessage } from "./components/ReviewMessage";
import { Scribe } from "./components/Scribe";
import { EMPTY_SECTIONS, isEmptySections } from "./components/Scribe/utils";
import { MIN_LENGTH, MIN_REVIEW_TIME } from "./constants";
import styles from "./styles.module.sass";

interface MemberSummaryCreateProps {
  patientId: string;
  visitId: string;
  hide: (event: MouseEvent) => void;
}

function hasContent(v: string | string[]) {
  if (typeof v === "string") {
    return v.length > MIN_LENGTH;
  } else {
    return v.every((item) => item.length > MIN_LENGTH);
  }
}

function stripNewLines(text: string) {
  return text.replace(/[\r\n]/g, "");
}

export function toImmutableVisitSections(
  sections: APITypesV1.SummarySections,
  language: string | null
): APITypesV1.ImmutableVisitSummarySection[] {
  // NB. we rely on the order here when converting in the other direction!
  return [
    {
      title: language === "sv" ? "Översikt" : "Overview",
      id: APITypesV1.SectionId.Introduction,
      body: sections.introduction,
    },
    {
      title: language === "sv" ? "Hjärta & cirkulation" : "Heart & circulation",
      id: APITypesV1.SectionId.Heart,
      body: sections.heart
        .map((l) => BULLET_MARKER + stripNewLines(l))
        .join("\n"),
    },
    {
      title: language === "sv" ? "Kropp" : "Body",
      id: APITypesV1.SectionId.Body,
      body: sections.body
        .map((l) => BULLET_MARKER + stripNewLines(l))
        .join("\n"),
    },
    {
      title: language === "sv" ? "Hud" : "Skin",
      id: APITypesV1.SectionId.Skin,
      body: sections.skin
        .map((l) => BULLET_MARKER + stripNewLines(l))
        .join("\n"),
    },
    {
      title: language === "sv" ? "Rekommendationer" : "Recommendations",
      id: APITypesV1.SectionId.Recommendations,
      body: sections.recommendations
        .map((l) => BULLET_MARKER + stripNewLines(l))
        .join("\n"),
    },
  ];
}

// TODO: remove once updated member app is rolled out
export function renderLegacySummary(
  sections: APITypesV1.ImmutableVisitSummarySection[]
) {
  return sections.map((s) => s.title + "\n\n" + s.body).join("\n\n");
}

export function MemberSummaryCreate({
  patientId,
  visitId,
  hide,
}: MemberSummaryCreateProps) {
  const api = useAPIClient();
  const patient = usePatient(patientId);

  const [visit, setVisit] = useState<Visit>();
  const [llmScan, setLlmScan] = useState<ImmutableScan>();
  const [sections, setSections] =
    useState<APITypesV1.SummarySections>(EMPTY_SECTIONS);
  const [customSections, setCustomSections] =
    useState<APITypesV1.SummarySections>(EMPTY_SECTIONS);
  const [generatedSections, setGeneratedSections] =
    useState<APITypesV1.SummarySections>(EMPTY_SECTIONS);
  const [originalSections, setOriginalSections] =
    useState<APITypesV1.SummarySections>();
  const [flavor, setFlavor] = useState<ScribeFlavor>(ScribeFlavor.Stable);
  const [summaryLanguage, setSummaryLanguage] = useState<string | null>();
  const [reviewSeconds, setReviewSeconds] = useState(0);
  const [isSent, setIsSent] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const timerRef = useRef<NodeJS.Timeout>();
  const startTimeRef = useRef<number>(new Date().getTime());

  const { saveProblemReportResult, saveReviewResult } = useLLMResults();
  const { logError } = useReporting();
  const { emitFeedbackDialog } = useFeedbackDialog();
  const { createVisit } = useVisitStore();

  const computeReviewData = useCallback(
    (
      generated: APITypesV1.SummarySections,
      saved: APITypesV1.SummarySections
    ) => {
      const toImmutableVisitSectionString = (
        s: APITypesV1.ImmutableVisitSummarySection
      ) => {
        return "# " + s.title + "\n" + s.body + "\n\n";
      };

      const savedSummary = toImmutableVisitSections(
        saved,
        summaryLanguage || null
      )
        .map(toImmutableVisitSectionString)
        .join("\n");

      const textLength = (s: APITypesV1.SummarySections) => {
        const ivs = toImmutableVisitSections(s, summaryLanguage || null);
        return ivs.map((s) => s.body.length).reduce((a, c) => a + c, 0);
      };

      const diffCount = textLength(generated) - textLength(saved);

      return { savedSummary, diffCount };
    },
    [summaryLanguage]
  );

  const handleFeedback = useCallback(
    ({ feedback, isLiked }: { feedback: string; isLiked: boolean }) => {
      if (llmScan && originalSections) {
        const { savedSummary, diffCount } = computeReviewData(
          sections,
          originalSections
        );

        saveReviewResult(
          llmScan,
          savedSummary,
          flavor,
          reviewSeconds,
          diffCount,
          isLiked
        );

        if (feedback.length > 0) {
          const category = isLiked ? "positive" : "negative";
          saveProblemReportResult(llmScan, { category, feedback });
        }
      }
    },
    [
      flavor,
      llmScan,
      originalSections,
      reviewSeconds,
      saveProblemReportResult,
      saveReviewResult,
      sections,
      computeReviewData,
    ]
  );

  const submit = useCallback(async () => {
    if (visitId) {
      // If no language is available from transcription, use the member's preferred language
      const language =
        (summaryLanguage ? summaryLanguage : patient?.preferredLanguage) ||
        null;

      const immutableVisitSummarySections = toImmutableVisitSections(
        sections,
        summaryLanguage || null
      );

      await api.immutableVisitSummary
        .createSummary(
          { patientId },
          {
            visitId,
            immutableVisitSummarySections,
            sendNotification: true,
            summaryLanguage: language,
          }
        )
        .result.catch(logError);

      // TODO: We continue writing to the legacy api until we have rolled out the updated members app. Remove this once done.
      if (visit?.startTime) {
        createVisit(patientId, {
          id: visitId,
          patientId,
          summaryText: renderLegacySummary(immutableVisitSummarySections),
          visitDate: visit.startTime,
          // followUpDate: visit.startTime.plus({ years: 1 }),
          audit: {},
        }).catch(logError);
      }

      emitFeedbackDialog(handleFeedback);
      setIsSent(true);
    }
  }, [
    api.immutableVisitSummary,
    createVisit,
    emitFeedbackDialog,
    handleFeedback,
    patientId,
    logError,
    patient?.preferredLanguage,
    sections,
    summaryLanguage,
    visit,
    visitId,
  ]);

  const formHandle = useFormHandle(submit);

  const handleOnSummary = useCallback(
    (s: APITypesV1.SummarySections) => {
      if (originalSections === undefined) {
        setOriginalSections(s);
      }

      if (flavor === ScribeFlavor.None) {
        setCustomSections(s);
      } else if (flavor === ScribeFlavor.Stable) {
        setGeneratedSections(s);
      }

      if (!isEmptySections(s)) {
        // Start timer if something was generated
        startTimeRef.current = new Date().getTime();
        timerRef.current = setInterval(() => {
          const reviewTime = Math.round(
            (new Date().getTime() - startTimeRef.current) / 1000
          );
          setReviewSeconds(reviewTime);
        }, 1000);
      }
      setSections(s);
    },
    [flavor, originalSections]
  );

  const handleOnFlavor = useCallback(
    (f: ScribeFlavor) => {
      if (f === ScribeFlavor.None) {
        setGeneratedSections(sections);
        setSections(customSections);
      } else if (f === ScribeFlavor.Stable) {
        setCustomSections(sections);
        setSections(generatedSections);
      }
      setFlavor(f);
    },
    [sections, customSections, generatedSections]
  );

  const remainingReviewTime = useMemo(() => {
    return timerRef.current ? Math.max(MIN_REVIEW_TIME - reviewSeconds, 0) : 0;
  }, [reviewSeconds]);

  const isEdited = useMemo(() => {
    return Object.values(sections).every(hasContent);
  }, [sections]);

  const isValid =
    !isSent &&
    timerRef.current === undefined &&
    remainingReviewTime === 0 &&
    isEdited;

  useEffect(() => {
    api.visit
      .fetchVisit({ patientId, visitId })
      .result.then((v) => setVisit(fromAPI.toVisit(v)))
      .catch(logError);
  }, [api.visit, logError, patientId, visitId]);

  useEffect(() => {
    if (timerRef.current && remainingReviewTime === 0) {
      clearInterval(timerRef.current);
      timerRef.current = undefined;
    }
  }, [remainingReviewTime]);

  return (
    <div className={styles.MemberSummaryCreate}>
      <div className={styles.container} data-drawervisible={drawerVisible}>
        <BackButtonClick onClick={hide}>
          {patient ? <PatientName patient={patient} /> : "Back"}
        </BackButtonClick>
        <div className={styles.title}>
          <PageHeader caption="Visit Summary" />
          {visit?.startTime && <DayMonthYearDate date={visit.startTime} />}
        </div>
        <Scribe
          patientId={patientId}
          visitId={visitId}
          showDetailsOnly={false}
          flavor={flavor}
          currentEditorSections={sections}
          onFlavor={handleOnFlavor}
          onSummary={handleOnSummary}
          onSummaryLanguage={setSummaryLanguage}
          onScan={setLlmScan}
          onDrawer={setDrawerVisible}
        />
        <div className={styles.content}>
          <form onSubmit={formHandle.onSubmit}>
            <SummaryEditor sections={sections} onChange={setSections} />
            <div className={styles.submit}>
              <SubmitButton handle={formHandle} disabled={!isValid}>
                Send summary
              </SubmitButton>
              <ReviewMessage
                isSent={isSent}
                remainingReviewTime={remainingReviewTime}
                isInvalid={!isValid}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
