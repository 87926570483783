import {
  $convertFromMarkdownString,
  $convertToMarkdownString,
  UNORDERED_LIST,
} from "@lexical/markdown";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import type { EditorState } from "lexical";
import { $getRoot } from "lexical";
import { useEffect, useRef } from "react";
import { DisableFormattingPlugin } from "./DisableFormattingPlugin";
import { SanitizePastePlugin } from "./SanitizePastePlugin";

interface EditorPluginProps {
  value: string;
  onChange: (value: string) => void;
}

export function EditorPlugins({ value, onChange }: EditorPluginProps) {
  const [editor] = useLexicalComposerContext();
  const isLoadedRef = useRef(false);

  useEffect(() => {
    // Init only once!
    if (value && !isLoadedRef.current) {
      isLoadedRef.current = true;
      editor.update(() => {
        const root = $getRoot(); // Get the root node
        root.clear();
        $convertFromMarkdownString(value, [UNORDERED_LIST]);

        // Move caret to the end inside update()
        const lastChild = root.getLastChild();
        if (lastChild) {
          lastChild.selectEnd();
        }
      });
    }
  }, [editor, value]);

  const handleChange = (editorState: EditorState) => {
    editorState.read(() => {
      onChange($convertToMarkdownString([UNORDERED_LIST]));
    });
  };

  return (
    <>
      <OnChangePlugin onChange={handleChange} />
      <HistoryPlugin />
      <MarkdownShortcutPlugin transformers={[UNORDERED_LIST]} />
      <ListPlugin />
      <DisableFormattingPlugin />
      <SanitizePastePlugin />
    </>
  );
}
