import { blobToBuffer } from "lib/audio/blob-to-buffer";
import { useEffect, useState } from "react";
import { useDashboardContext } from "render/pages/DashboardPage/context/DashboardContext";

export function useAudioBuffer(blob?: Blob) {
  const { audioContext } = useDashboardContext();
  const [audioBuffer, setAudioBuffer] = useState<AudioBuffer | undefined>();

  useEffect(() => {
    if (!blob) {
      return;
    }

    blobToBuffer(audioContext, blob)
      .then(setAudioBuffer)
      .catch((error) => {
        console.error(
          "useAudioBuffer: Failed to convert blob to audio buffer",
          error
        );
        setAudioBuffer(undefined);
      });

    return () => {
      setAudioBuffer(undefined);
    };
  }, [audioContext, blob, setAudioBuffer]);

  return audioBuffer;
}
