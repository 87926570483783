enum Volume {
  Muted = 0.001,
  Unmuted = 1,
}

export type AudioPlayerOptions = {
  ctx: AudioContext;
  muted?: boolean;
  debug?: boolean;
};

export class AudioPlayer {
  private ctx: AudioContext;
  private sources = new Map<string, AudioBufferSourceNode>();
  private gainNode: GainNode;
  private muted = false;
  public debug = false;

  constructor(options: AudioPlayerOptions) {
    const { ctx, muted = false, debug = false } = options;
    this.ctx = ctx;
    this.debug = debug;
    this.gainNode = new GainNode(this.ctx);
    this.gainNode.connect(this.ctx.destination);
    this.muted = muted;
    this.setVolume(muted ? Volume.Muted : Volume.Unmuted);
  }

  log = (message: string) => {
    if (this.debug) {
      console.log(message);
    }
  };

  createSourceNode = (audio: Audio) => {
    this.log(`AudioPlayer: Creating source node for audio id: ${audio.id}`);
    const source = this.ctx.createBufferSource();
    source.buffer = audio.buffer;
    source.connect(this.gainNode);
    source.loop = audio.loop;

    this.sources.set(audio.id, source);

    return source;
  };

  disposeSourceNode = (id: Audio["id"]) => {
    const source = this.sources.get(id);
    if (source) {
      this.log(`AudioPlayer: Disposing source node for audio id: ${id}`);
      source.stop();
      source.disconnect();
      this.sources.delete(id);
    }
  };

  disposeAllSourceNodes = () => {
    this.log("AudioPlayer: Disposing all source nodes");
    this.sources.forEach((source) => {
      source.stop();
      source.disconnect();
    });
    this.sources.clear();
  };

  private disposeGainNode = () => {
    this.log("AudioPlayer: Disposing gain node");
    this.gainNode.disconnect();
  };

  play = async (audio: Audio) => {
    await this.ctx.resume();

    this.log(`AudioPlayer: Playing audio id: ${audio.id}`);

    this.stop(audio);

    const source = this.createSourceNode(audio);
    this.setVolume(this.muted ? Volume.Muted : Volume.Unmuted);
    source.start();

    return source;
  };

  stop(audio: Audio): void {
    this.log(`AudioPlayer: Stopping audio id: ${audio.id}`);
    this.disposeSourceNode(audio.id);
  }

  fade = (volume: number, duration: number) => {
    this.log(
      `AudioPlayer: Fading to volume: ${volume} over duration: ${duration}`
    );
    this.gainNode.gain.cancelScheduledValues(this.ctx.currentTime);
    this.gainNode.gain.setValueAtTime(
      this.gainNode.gain.value,
      this.ctx.currentTime
    );
    this.gainNode.gain.exponentialRampToValueAtTime(
      volume,
      this.ctx.currentTime + duration
    );
  };

  setVolume = (volume: number) => {
    this.log(`AudioPlayer: Setting volume to: ${volume}`);
    this.gainNode.gain.value = volume;
  };

  mute = (muted: boolean) => {
    this.log(`AudioPlayer: Muting: ${muted}`);
    this.muted = muted;
    const volume = muted ? Volume.Muted : Volume.Unmuted;
    this.fade(volume, 0.2);
  };

  dispose = () => {
    this.log("AudioPlayer: Disposing audio player");
    this.disposeAllSourceNodes();
    this.disposeGainNode();
  };
}

export interface AudioOptions {
  id: string;
  buffer: AudioBuffer;
  loop?: boolean;
}
export class Audio {
  readonly id: string;
  readonly buffer: AudioBuffer;
  readonly loop: boolean;

  constructor({ id, buffer, loop = false }: AudioOptions) {
    this.id = id;
    this.buffer = buffer;
    this.loop = loop;
  }
}
