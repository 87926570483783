import styles from "./styles.module.sass";

export function PlusSign() {
  return (
    <svg
      className={styles.PlusSign}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2.75 8h10.5M8 2.75v10.5" />
    </svg>
  );
}
