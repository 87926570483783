import { Plot } from "lib/api/cardio";
import { clamp } from "lib/math";
import { useCallback, useEffect, useMemo, useState } from "react";
import { PatientMetrics } from "render/hooks/patient/usePatientMetrics";
import { ECGGraphMarkerProps } from "../components/ECGGraphMarker";

export type ECGGraphMarkerMetrics = Pick<
  PatientMetrics["cardio"]["ecg"],
  "p" | "pr" | "qrs" | "qt"
>;

export type ECGGraphMarkerData = Pick<
  ECGGraphMarkerProps,
  "x" | "y" | "width" | "label" | "duration"
>;

export type ECGGraphMarkerController = {
  show: boolean;
  data: ECGGraphMarkerData | undefined;
};

export type ECGGraphMarkerControllers = Record<
  keyof ECGGraphMarkerMetrics,
  ECGGraphMarkerController
>;

/**
 * Facade hook which:
 * 1. Calculates the position of ECG segment markers (=P, PR, QRS, QT) based on the provided ECG signal and ECG metrics
 * 2. Controls the reveal of the markers based on the progress of the ECG signal
 */
export function useECGGraphMarkers({
  metrics,
  plot,
  progress = NaN,
  enabled = false,
  toY,
}: {
  progress?: number;
  enabled?: boolean;
  metrics: ECGGraphMarkerMetrics;
  plot: Plot;
  toY: (value: number) => number;
}): ECGGraphMarkerControllers {
  const { p, pr, qrs, qt } = metrics;

  const latestP = p?.at(0)?.unit["ms"];
  const latestPR = pr?.at(0)?.unit["ms"];
  const latestQRS = qrs?.at(0)?.unit["ms"];
  const latestQT = qt?.at(0)?.unit["ms"];

  const markerData: Record<
    keyof ECGGraphMarkerMetrics,
    ECGGraphMarkerData | undefined
  > = useMemo(() => {
    // never accept partial data
    if (
      plot.r_peak_indices?.length !== 4 ||
      !latestP ||
      !latestPR ||
      !latestQRS ||
      !latestQT
    ) {
      return {
        p: undefined,
        pr: undefined,
        qrs: undefined,
        qt: undefined,
      };
    }

    const ecgSignalUtils = {
      millisToSeconds: (ms: number) => ms * 0.001,

      fromIndex: {
        toTimestamp: (index: number) =>
          (index / plot.signal.length) * plot.signal_length_sec,
        toPercent: (index: number) => (index / plot.signal.length) * 100,
      },

      fromTimestamp: {
        toIndex: (time: number) =>
          clamp(
            Math.floor((time / plot.signal_length_sec) * plot.signal.length),
            0,
            plot.signal.length
          ),
      },
    };

    const [rPeak1Index, rPeak2Index, rPeak3Index, rPeak4Index] =
      plot.r_peak_indices;

    // P marker data
    const rPeak1Timestamp = ecgSignalUtils.fromIndex.toTimestamp(rPeak1Index);
    const pTimestamp =
      rPeak1Timestamp -
      ecgSignalUtils.millisToSeconds(latestQRS) * 0.5 -
      ecgSignalUtils.millisToSeconds(latestPR);
    const pIndex = ecgSignalUtils.fromTimestamp.toIndex(pTimestamp);

    const pMarker: ECGGraphMarkerData = {
      x: ecgSignalUtils.fromIndex.toPercent(pIndex),
      y: toY(pIndex),
      label: "P",
    };

    // PR marker
    const rPeak2Timestamp = ecgSignalUtils.fromIndex.toTimestamp(rPeak2Index);
    const prStartTimestamp =
      rPeak2Timestamp -
      ecgSignalUtils.millisToSeconds(latestQRS) * 0.5 -
      ecgSignalUtils.millisToSeconds(latestPR);
    const prEndTimestamp =
      prStartTimestamp + ecgSignalUtils.millisToSeconds(latestPR);
    const prStartIndex = ecgSignalUtils.fromTimestamp.toIndex(prStartTimestamp);
    const prEndIndex = ecgSignalUtils.fromTimestamp.toIndex(prEndTimestamp);

    const prMarker: ECGGraphMarkerData = {
      duration: latestPR,
      x: ecgSignalUtils.fromIndex.toPercent(prStartIndex),
      width: ecgSignalUtils.fromIndex.toPercent(prEndIndex - prStartIndex),
      label: "PQ",
    };

    // QRS marker
    const rPeak3Timestamp = ecgSignalUtils.fromIndex.toTimestamp(rPeak3Index);
    const qrsStartTimestamp =
      rPeak3Timestamp - ecgSignalUtils.millisToSeconds(latestQRS) * 0.5;
    const qrsEndTimestamp =
      qrsStartTimestamp + ecgSignalUtils.millisToSeconds(latestQRS);
    const qrsStartIndex =
      ecgSignalUtils.fromTimestamp.toIndex(qrsStartTimestamp);
    const qrsEndIndex = ecgSignalUtils.fromTimestamp.toIndex(qrsEndTimestamp);

    const qrsMarker: ECGGraphMarkerData = {
      duration: latestQRS,
      x: ecgSignalUtils.fromIndex.toPercent(qrsStartIndex),
      width: ecgSignalUtils.fromIndex.toPercent(qrsEndIndex - qrsStartIndex),
      label: "QRS",
    };

    // QT marker
    const rPeak4Timestamp = ecgSignalUtils.fromIndex.toTimestamp(rPeak4Index);
    const qtStartTimestamp =
      rPeak4Timestamp - ecgSignalUtils.millisToSeconds(latestQRS) * 0.5;
    const qtEndTimestamp =
      qtStartTimestamp + ecgSignalUtils.millisToSeconds(latestQT);
    const qtStartIndex = ecgSignalUtils.fromTimestamp.toIndex(qtStartTimestamp);
    const qtEndIndex = ecgSignalUtils.fromTimestamp.toIndex(qtEndTimestamp);

    const qtMarker: ECGGraphMarkerData = {
      duration: latestQT,
      x: ecgSignalUtils.fromIndex.toPercent(qtStartIndex),
      width: ecgSignalUtils.fromIndex.toPercent(qtEndIndex - qtStartIndex),
      label: "QTC",
    };

    return {
      p: pMarker,
      pr: prMarker,
      qrs: qrsMarker,
      qt: qtMarker,
    };
  }, [plot, latestP, latestPR, latestQRS, latestQT, toY]);

  const [showP, setShowP] = useState(false);
  const [showPR, setShowPR] = useState(false);
  const [showQRS, setShowQRS] = useState(false);
  const [showQT, setShowQT] = useState(false);

  const revealThreshold = useCallback(
    (metric: keyof ECGGraphMarkerMetrics) => {
      return (markerData[metric]?.x ?? 0) / 100;
    },
    [markerData]
  );

  useEffect(() => {
    if (!enabled) {
      return;
    }

    if (!showP && progress > revealThreshold("p")) {
      setShowP(true);
    }

    if (!showPR && progress > revealThreshold("pr")) {
      setShowPR(true);
    }

    if (!showQRS && progress > revealThreshold("qrs")) {
      setShowQRS(true);
    }

    if (!showQT && progress > revealThreshold("qt")) {
      setShowQT(true);
    }
  }, [enabled, showP, showPR, showQRS, showQT, progress, revealThreshold]);

  return useMemo<ECGGraphMarkerControllers>(() => {
    return {
      p: {
        show: showP,
        data: markerData.p,
      },
      pr: {
        show: showPR,
        data: markerData.pr,
      },
      qrs: {
        show: showQRS,
        data: markerData.qrs,
      },
      qt: {
        show: showQT,
        data: markerData.qt,
      },
    };
  }, [showP, showPR, showQRS, showQT, markerData]);
}
