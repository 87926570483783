import { sliceAudioBuffer } from "lib/audio/slice-audio-buffer";
import { useEffect, useState } from "react";
import { useDashboardContext } from "render/pages/DashboardPage/context/DashboardContext";

interface SliceOptions {
  sliceStartSec: number;
  sliceEndSec: number;
}

export function useAudioBufferSlice(
  inputBuffer: AudioBuffer | undefined,
  { sliceStartSec, sliceEndSec }: SliceOptions
) {
  const { audioContext } = useDashboardContext();
  const [slice, setSlice] = useState<AudioBuffer | undefined>(inputBuffer);

  useEffect(() => {
    if (!inputBuffer || sliceStartSec < 0 || sliceStartSec >= sliceEndSec) {
      return;
    }

    sliceAudioBuffer(audioContext, inputBuffer, {
      sliceStartSec,
      sliceEndSec,
      fade: 0.01,
    })
      .then(setSlice)
      .catch((error) => {
        console.error("useAudioBlobSlice: Failed to slice audio blob", error);
        setSlice(inputBuffer);
      });

    return () => {
      setSlice(undefined);
    };
  }, [audioContext, inputBuffer, setSlice, sliceStartSec, sliceEndSec]);

  return slice;
}
