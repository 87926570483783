import { BulletEditor } from "../BulletEditor";
import styles from "./styles.module.sass";

interface BulletSectionEditorProps {
  title: string;
  content: string[];
  onChange: (value: string[]) => void;
}

export function BulletSectionEditor({
  title,
  content,
  onChange,
}: BulletSectionEditorProps) {
  return (
    <div className={styles.BulletSectionEditor}>
      <h3 className={styles.title}>{title}</h3>
      <BulletEditor
        placeholder={"Enter key points here..."}
        content={content}
        onContentChange={onChange}
      />
    </div>
  );
}
