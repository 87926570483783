import {
  MetricUnitContext,
  MetricsUnits,
} from "render/context/MetricsUnitContext";
import { EntryPoint } from "./components/EntryPoint";
import { AvatarContext } from "./context/AvatarContext";
import { DashboardContext } from "./context/DashboardContext";
import { PatientDataContext } from "./context/PatientDataContext";
import styles from "./styles.module.sass";

const units: MetricsUnits = {
  length: "metric",
};

interface DashboardPageProps {
  patientId: string;
}

export function DashboardPage({ patientId }: DashboardPageProps) {
  return (
    <MetricUnitContext units={units}>
      <PatientDataContext patientId={patientId}>
        <AvatarContext>
          <DashboardContext>
            <div
              className={styles.DashboardPage}
              onContextMenu={(event) => event.preventDefault()}
            >
              <EntryPoint />
            </div>
          </DashboardContext>
        </AvatarContext>
      </PatientDataContext>
    </MetricUnitContext>
  );
}
