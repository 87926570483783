import { clamp } from "lib/math";

export function getCaretPosition(element: HTMLElement): number {
  const selection = window.getSelection();
  if (
    selection &&
    selection.rangeCount > 0 &&
    selection.anchorNode?.parentNode === element
  ) {
    return selection.getRangeAt(0).startOffset;
  }
  return 0;
}

export function setCaretPosition(element: HTMLElement, position: number) {
  const range = document.createRange();
  const selection = window.getSelection();

  if (element.firstChild && element.firstChild.nodeType === Node.TEXT_NODE) {
    const textNode = element.firstChild as Text;
    const textLength = textNode.textContent?.length || 0;

    // Ensure position is within valid bounds
    const safePosition = clamp(position, 0, textLength);

    range.setStart(textNode, safePosition);
    range.collapse(true);

    selection?.removeAllRanges();
    selection?.addRange(range);
  }
}

export function getSelectedTextLength(element: HTMLElement): number {
  const selection = window.getSelection();
  if (
    selection &&
    selection.rangeCount > 0 &&
    selection.anchorNode?.parentNode === element
  ) {
    return selection.toString().length;
  }
  return 0;
}

export function insertTextAtCursor(text: string) {
  const selection = window.getSelection();
  if (!selection || !selection.rangeCount) {
    return;
  }

  const range = selection.getRangeAt(0);
  range.deleteContents(); // Remove selected text if any

  let textNode: Text | null = null;
  let newOffset = 0;
  const startContainer = range.startContainer;
  const startOffset = range.startOffset;

  if (startContainer.nodeType === Node.TEXT_NODE) {
    // If inside an existing text node, insert text directly
    textNode = startContainer as Text;
    textNode.insertData(startOffset, text);
    newOffset = startOffset + text.length;
  } else {
    // If inside an empty element, create a new text node
    textNode = document.createTextNode(text);
    range.insertNode(textNode);
    newOffset = text.length;
  }

  // Move cursor to the end of the inserted text
  if (textNode) {
    range.setStart(textNode, newOffset);
    range.setEnd(textNode, newOffset);
  }

  selection.removeAllRanges();
  selection.addRange(range);

  // Dispatch an "input" event to notify React or other listeners
  const editableElement =
    range.commonAncestorContainer.nodeType === Node.ELEMENT_NODE
      ? (range.commonAncestorContainer as HTMLElement)
      : range.commonAncestorContainer.parentElement;

  if (editableElement) {
    setTimeout(() => {
      const event = new InputEvent("input", {
        bubbles: true,
        cancelable: true,
      });
      editableElement.dispatchEvent(event);
    }, 0);
  }
}

export function flattenText(text: string) {
  return text.replace(/[\r\n]+/g, " "); // Remove new lines
}
