import { APITypesV1 } from "@cur8/api-client";

export const firstInstruction = `You are an expert in helping doctors improve on their summaries according to their instructions. Requirements for the improved text:\n
* Structured in five logical sections according to the schema: introduction + four sections with string array items.\n
* Just reformat the text if asked to do so, do not add information or advice.\n
* Keep the language used in the new summary unless instructed otherwise\n
* Never reference the instructions given in the new summary\n
* The new summary is always a slightly modified version of the previous summary. Never add comments or explanations to the new summary unless instructed to do so. You use the comments property for those.\n
* If the doctor asks for anything not related to the text, repeat the existing summary and the comment ""Sorry, can't help with that."" Always respond with an updated summary and a short comment.`;

export function repeatInstruction(
  currentEditorSections: APITypesV1.SummarySections
) {
  return `The doctor will now provide further instructions.\n
  * Consider the input and modify the summary as long as the request relates to the summary. If the request has nothing to do with the text or the summary, repeat the old summary and provide a comment that you only help with summaries.\n
  * If the doctor asks to add something completely new to an string array section, add it as a new item in the array.\n
  * Always respond with an updated summary and a short comment on how you addressed the instructions.\n
  * Do not recommend seeking medical advice or refer to a healthcare provider, you are the healthcare provider.\n
  * Double check that the comment accurately reflects your provided modification of the summary.\n
  * Keep the style and formatting unless told otherwise.\n
  * Always structure the summary in logical paragraphs using double line breaks for readability.\n\n
  Current summary:\n${escape(JSON.stringify(currentEditorSections))}`;
}

export const firstPrompt =
  "Let's iterate on the summary. Describe how you would like to change it. You can also use one of the suggestions below.";

export const tools = [
  {
    type: "function",
    function: {
      name: "onResponse",
      description: "Stores minified JSON results",
      parameters: {
        type: "object",
        properties: {
          summary: {
            type: "string",
            description:
              "The summary updated according to user instructions. If you cannot provide an updated summary, the previous one is returned again.",
          },
          comment: {
            type: "string",
            description: "Short comment in response to the user input.",
          },
        },
        required: ["summary", "comment"],
      },
    },
  },
];

function escape(text: string) {
  return text.replace(/"/g, "'");
}

export type ImproveResponse = {
  updatedSections: APITypesV1.SummarySections;
  whatWasChangedComment: string;
};

export const response_format = {
  type: "json_schema",
  json_schema: {
    name: "updated_sections_and_comment",
    schema: {
      type: "object",
      properties: {
        updatedSections: {
          type: "object",
          properties: {
            introduction: {
              type: "string",
            },
            heart: {
              type: "array",
              items: {
                type: "string",
              },
            },
            body: {
              type: "array",
              items: {
                type: "string",
              },
            },
            skin: {
              type: "array",
              items: {
                type: "string",
              },
            },
            recommendations: {
              type: "array",
              items: {
                type: "string",
              },
            },
          },
          required: [
            "introduction",
            "heart",
            "body",
            "skin",
            "recommendations",
          ],
          additionalProperties: false,
        },
        whatWasChangedComment: {
          type: "string",
        },
      },
      required: ["updatedSections", "whatWasChangedComment"],
      additionalProperties: false,
    },
    strict: true,
  },
};
