import { ReactNode, useCallback, useEffect, useState } from "react";
import { Typography } from "render/ui/presentation/Typography";
import CloseIcon from "./close.svg?react";
import styles from "./styles.module.sass";

interface RightDrawerProps {
  title: string;
  onClose?: () => void;
  children: ReactNode;
  active: boolean;
}

export function RightDrawer({
  title,
  onClose,
  children,
  active,
}: RightDrawerProps) {
  const [renderComplete, setRenderComplete] = useState(false);

  useEffect(() => {
    if (active) {
      setRenderComplete(true);
    }
  }, [active]);

  const onAnimationEnd = useCallback(() => {
    setRenderComplete(active);
  }, [active]);

  return (
    <div
      data-active={active}
      data-complete={renderComplete}
      className={styles.RightDrawer}
      onAnimationEnd={onAnimationEnd}
    >
      <div className={styles.titleRow}>
        <Typography variant="title-m">{title}</Typography>
        <button onClick={onClose}>
          <CloseIcon />
        </button>
      </div>
      <div className={styles.children}>{children}</div>
    </div>
  );
}
