import { Patient } from "@cur8/rich-entity";
import { AvatarPodium } from "lib/avatar/podium";
import { useScreenProject } from "render/hooks/three/useProject";
import { useAvatarContext } from "render/pages/DashboardPage/context/AvatarContext";
import { Layer } from "render/pages/DashboardPage/types";
import * as THREE from "three";
import { BodyMeasurementsLayer } from "./layers/BodyMeasurementsLayer";
import { CardioLayer } from "./layers/CardioLayer";
import { CardioVascularSystemLayer } from "./layers/CardioVascularSystemLayer";
import { DetectedLesionsLayer } from "./layers/DetectedLesionsLayer";
import { DiabetesLayer } from "./layers/DiabetesLayer";
import { HeartLayer } from "./layers/HeartLayer";
import { PiezoLayer } from "./layers/PiezoLayer";
import { PulseWaveLayer } from "./layers/PulseWaveLayer";
import { TrackedLesionsLayer } from "./layers/TrackedLesionsLayer";
import styles from "./styles.module.sass";

interface OverlaysProps {
  layer: Layer;
  canvas: HTMLCanvasElement;
  camera: THREE.PerspectiveCamera;
  patient: Patient;
  podium: AvatarPodium;
}

export function Overlays({
  camera,
  patient,
  podium,
  layer,
  canvas,
}: OverlaysProps) {
  const projector = useScreenProject(camera, podium, canvas);

  const smpl = useAvatarContext().smpl;

  const front = smpl.front.areas;

  return (
    <div className={styles.Overlays} data-layer={layer}>
      {front && (
        <CardioVascularSystemLayer
          layer={layer}
          areas={front}
          projector={projector}
        />
      )}

      {front && (
        <CardioLayer
          layer={layer}
          patient={patient}
          areas={front}
          projector={projector}
        />
      )}

      {front && (
        <PulseWaveLayer layer={layer} areas={front} projector={projector} />
      )}

      {front && (
        <PiezoLayer layer={layer} areas={front} projector={projector} />
      )}

      {front && (
        <HeartLayer layer={layer} areas={front} projector={projector} />
      )}

      {front && (
        <BodyMeasurementsLayer
          layer={layer}
          areas={front}
          projector={projector}
        />
      )}

      {front && (
        <DiabetesLayer
          layer={layer}
          patient={patient}
          areas={front}
          projector={projector}
        />
      )}

      {front && (
        <DetectedLesionsLayer
          layer={layer}
          areas={front}
          projector={projector}
        />
      )}

      {front && (
        <TrackedLesionsLayer
          layer={layer}
          areas={front}
          projector={projector}
        />
      )}
    </div>
  );
}
