import { fromAPI, Visit } from "@cur8/rich-entity";
import { silenceAbort } from "lib/error";
import { useEffect, useState } from "react";
import { useAPIClient } from "render/context/APIContext";
import { useReporting } from "../useReporting";

export function useVisit({
  patientId,
  visitId,
}: {
  patientId: string | undefined;
  visitId: string | undefined;
}) {
  const { logError } = useReporting();
  const [visit, setVisit] = useState<Visit>();
  const apiClient = useAPIClient();

  useEffect(() => {
    if (patientId == null || visitId == null) {
      return;
    }

    const req = apiClient.visit.fetchVisit({
      patientId,
      visitId,
    });

    req.result
      .then((result) => {
        return fromAPI.toVisit(result);
      })
      .then(setVisit)
      .catch(logError)
      .catch(silenceAbort);
  }, [apiClient.visit, logError, patientId, visitId]);

  return visit;
}
