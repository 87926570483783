import styles from "./styles.module.sass";

export type CheckmarkType = "unchecked" | "filled" | "notfilled";

export function Checkmark({ type = "notfilled" }: { type?: CheckmarkType }) {
  return (
    <svg
      className={styles.Checkmark}
      data-type={type}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="10" />
      <path d="m8 11.667 2.667 2.666L16 9" />
    </svg>
  );
}
