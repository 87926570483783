import { EcgQRS, Risk } from "@cur8/health-risks-calc";
import { Metric } from "lib/metric";
import { useMemo } from "react";
import { RISK_COLORS } from "render/risk";
import { RampUpNumber } from "render/ui/format/RampUpNumber";
import { FloatingValueTarget } from "render/ui/presentation/FloatingValueTarget";
import { ValueItem } from "render/ui/symbol/ValueItem";
import { RampUpNumberAnimationConfig } from "../HeartEcgOverlay.animations";

interface EcgSegmentQRSProps {
  active?: boolean;
  qrs: Metric<"cardio.ecg.segments.qrs">[] | undefined;
  width?: number;
}

export function EcgSegmentQRS({
  active = true,
  qrs,
  width = 150,
}: EcgSegmentQRSProps) {
  const latest = qrs?.at(0);

  const risk = useMemo(() => {
    if (latest) {
      return EcgQRS.rangesFor().findRisk(latest.unit);
    }
  }, [latest]);

  const color = RISK_COLORS[risk ?? Risk.Unknown];

  return (
    <FloatingValueTarget
      label={<>Ventricular</>}
      value={
        <ValueItem
          value={
            latest && active ? (
              <RampUpNumber
                value={latest.unit.ms}
                precision={0}
                duration={RampUpNumberAnimationConfig.duration}
                ease={RampUpNumberAnimationConfig.ease}
              />
            ) : (
              "--"
            )
          }
          unit="ms"
          size="huge"
        />
      }
      color={color}
      align="right"
      width={width}
    />
  );
}
