import { $createListItemNode, $createListNode } from "@lexical/list";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
  $createParagraphNode,
  $createTextNode,
  $getSelection,
  $insertNodes,
  COMMAND_PRIORITY_CRITICAL,
  LexicalNode,
  PASTE_COMMAND,
} from "lexical";
import { useEffect } from "react";

function parseMarkdownToNodes(markdown: string): LexicalNode[] {
  const lines = markdown.split("\n");
  const nodes: LexicalNode[] = [];
  let currentListNode: ReturnType<typeof $createListNode> | null = null;

  lines.forEach((line, index) => {
    const trimmed = line.trim();

    // 1. If it's a list item (unordered list), process it
    if (trimmed.startsWith("- ") || trimmed.startsWith("* ")) {
      if (!currentListNode) {
        currentListNode = $createListNode("bullet");
        nodes.push(currentListNode);
      }

      const listItemNode = $createListItemNode();
      listItemNode.append($createTextNode(trimmed.substring(2))); // Remove "- " or "* " from the item text
      currentListNode.append(listItemNode);
    } else {
      // 2. If it's regular text (non-list)
      if (currentListNode) {
        currentListNode = null; // End the list node when switching to regular text
      }

      if (line.trim() === "") {
        // 3. If the line is empty (represents a newline), add a ParagraphNode
        nodes.push($createParagraphNode()); // This represents a new paragraph (newline)
      } else {
        // 4. Add regular text as a TextNode
        const textNode = $createTextNode(line);
        nodes.push(textNode);
        // If it's not the last line, add a line break (i.e., new line within regular text)
        if (index < lines.length - 1 && line.trim() !== "") {
          nodes.push($createTextNode("\n")); // Add newline as text node
        }
      }
    }
  });

  return nodes; // Return all the processed nodes
}

export function SanitizePastePlugin() {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    const removeStylesFromPaste = (event: ClipboardEvent) => {
      event.preventDefault();
      const markdown = event.clipboardData?.getData("text/plain") || "";

      editor.update(() => {
        const selection = $getSelection();
        if (selection) {
          const nodes = parseMarkdownToNodes(markdown);
          $insertNodes(nodes);
        }
      });

      return true; // Prevent Lexical from handling the paste
    };

    return editor.registerCommand(
      PASTE_COMMAND,
      removeStylesFromPaste,
      COMMAND_PRIORITY_CRITICAL
    );
  }, [editor]);

  return null;
}
