import { APITypesV1 } from "@cur8/api-client";
import { ImmutableScan } from "@cur8/rich-entity";
import { ScribeFlavor } from "lib/doctor-scribe/types";
import { useCallback } from "react";
import { useAPIClient } from "render/context/APIContext";
import { useMSAL } from "render/context/MSALContext";

export type ProblemReport = {
  category: string;
  feedback: string;
};

/**
 * Save problem report
 *
 */

export function useLLMResults() {
  const api = useAPIClient();
  const { auth } = useMSAL();

  const saveReviewResult = useCallback(
    async (
      scan: ImmutableScan,
      savedSummary: string,
      flavor: ScribeFlavor,
      readTime: number,
      diffCount: number,
      isLiked: boolean
    ) => {
      const internalData: APITypesV1.InternalReviewResult = {
        $type: "InternalReviewResult",
        savedSummary,
        flavor,
        readTime,
        user: auth?.account?.username || "unknown",
        diffCount,
        isLiked,
      };

      await api.scan.createScanResult({
        patientId: scan.patientId,
        scanId: scan.id,
        scanVersion: scan.version,
        resultName: "reviewResult",
        state: APITypesV1.ResultState.Complete,
        internalData,
      }).result;
    },
    [api, auth]
  );

  const saveProblemReportResult = useCallback(
    async (scan: ImmutableScan, problem: ProblemReport) => {
      const reporter = auth?.account?.name;
      const user = auth?.account?.username;

      api.scan.createScanResult({
        patientId: scan.patientId,
        scanId: scan.id,
        scanVersion: scan.version,
        resultName: "internalProblemReport",
        state: APITypesV1.ResultState.Complete,
        internalData: {
          $type: "InternalProblemReport",
          ...problem,
          reporter,
          user,
        },
      });
    },
    [api, auth]
  );

  return { saveReviewResult, saveProblemReportResult };
}
