import { useDelay } from "render/hooks/useDelay";
import HeartImg from "./assets/heart.png";
import { Arrow } from "./components/Arrow";
import {
  getArrowAnimationConfig,
  HeartEcgOverlayTimelineSteps,
} from "./HeartEcgOverlay.animations";
import styles from "./styles.module.sass";

import { useUniq } from "render/assets/svg/gradient";
import { EcgSegmentP } from "./components/EcgSegmentP";
import { EcgSegmentPR } from "./components/EcgSegmentPR";
import { EcgSegmentQRS } from "./components/EcgSegmentQRS";
import { EcgSegmentQTC } from "./components/EcgSegmentQTC";

interface HeartEcgOverlayProps {
  animate?: boolean;
}

export function HeartEcgOverlay({ animate = false }: HeartEcgOverlayProps) {
  const animateTopArrow = useDelay({
    active: animate,
    delay: getArrowAnimationConfig(HeartEcgOverlayTimelineSteps.ArrowTop).delay,
  });
  const animateMidArrows = useDelay({
    active: animate,
    delay: getArrowAnimationConfig(HeartEcgOverlayTimelineSteps.ArrowMid).delay,
  });
  const animateBottomArrows = useDelay({
    active: animate,
    delay: getArrowAnimationConfig(HeartEcgOverlayTimelineSteps.ArrowBottom)
      .delay,
  });

  const heartFill = useUniq("heart-fill");
  const heartFilter = useUniq("heart");
  const chamberLeftFilter = useUniq("chamber-left");
  const chamberRightFilter = useUniq("chamber-right");

  return (
    <div className={styles.HeartEcgOverlay}>
      <img className={styles.background} src={HeartImg} alt="Blurred Heart" />
      <svg
        width="441"
        height="667"
        viewBox="0 0 441 667"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Heart">
          <g data-id="base" opacity="0.08" filter={heartFilter.ref}>
            <path
              d="M276.783 656C237.212 656 133.173 624.944 106.427 571.399C79.6799 517.854 34.5904 469.742 34.5904 426.827C34.5904 346.51 11 357.06 11 304.744C11 252.429 24.2083 175.826 52 158C151 94.5 138.793 18.7617 276.783 11.3175C435.587 2.75038 421.791 170.001 427.003 220.143C432.936 277.214 428.481 364.496 427.005 397.913C417.358 616.377 313.358 656 276.783 656Z"
              fill={heartFill.ref}
            />
          </g>

          <g id="chamber-right" opacity="0.12" filter={chamberRightFilter.ref}>
            <path
              d="M98.9846 365.201C98.9846 476.782 195.234 531.195 212.153 513.271L208.38 354.095L188.814 357.268L208.38 338.23C208.38 193.334 92.5367 228.765 79.9474 283.762C71.0832 322.486 87.3512 339.288 105.86 355.153C124.369 371.017 98.9846 356.751 98.9846 365.201Z"
              fill="#38B8D9"
            />
          </g>
          <g id="chamber-left" opacity="0.12" filter={chamberLeftFilter.ref}>
            <path
              d="M396.625 182.231C361.51 119.937 242.446 144.56 284.61 261.76C286.634 267.386 292.444 282.551 309.906 286.902L302.286 290.537C298.12 291.074 293.949 291.626 294.621 294.193C323.269 403.554 307.762 514.171 307.762 514.171C363.728 521.948 421.574 434.305 402.189 293.292C399.362 269.017 387.519 276.212 386.306 272.716C386.306 272.716 431.739 244.524 396.625 182.231Z"
              fill="#38B8D9"
            />
          </g>
          <Arrow
            id="arrow-top"
            animate={animateTopArrow}
            d="M171 182.332C232 208.999 262 261.332 262 328.332"
          />
          <Arrow
            id="arrow-mid-right"
            animate={animateMidArrows}
            d="M265.874 394.332C265.874 491.999 270.415 527.332 225 552.332"
          />
          <Arrow
            id="arrow-mid-left"
            animate={animateMidArrows}
            d="M266.139 394.332C266.139 448.332 261.139 527.332 311.139 552.332"
          />
          <Arrow
            id="arrow-bottom-right"
            animate={animateBottomArrows}
            d="M207 557.332C148 570.332 98 545.332 72 483.332"
          />
          <Arrow
            id="arrow-bottom-left"
            animate={animateBottomArrows}
            d="M332 556.332C395 562.332 423 486.332 423 409.332"
          />
        </g>
        <defs>
          <filter
            id={heartFilter.id}
            x="0"
            y="0"
            width="441"
            height="667"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation="5.5"
              result="effect1_foregroundBlur_472_53"
            />
          </filter>
          <filter
            id={chamberRightFilter.id}
            x="69.459"
            y="227.864"
            width="150.693"
            height="296.768"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation="4"
              result="effect1_foregroundBlur_528_93"
            />
          </filter>
          <filter
            id={chamberLeftFilter.id}
            x="267.689"
            y="141.002"
            width="150.252"
            height="381.647"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation="4"
              result="effect1_foregroundBlur_528_93"
            />
          </filter>
          <radialGradient
            id={heartFill.id}
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(319.586 334.428) rotate(90) scale(198.326 78.676)"
          >
            <stop stopColor="#D6E8EC" />
            <stop offset="1" stopColor="#46B2CD" />
          </radialGradient>
        </defs>
      </svg>
    </div>
  );
}

HeartEcgOverlay.EcgSegmentP = EcgSegmentP;
HeartEcgOverlay.EcgSegmentPR = EcgSegmentPR;
HeartEcgOverlay.EcgSegmentQRS = EcgSegmentQRS;
HeartEcgOverlay.EcgSegmentQTC = EcgSegmentQTC;
