import { APITypesV1 } from "@cur8/api-client";
import { Annotation, ImmutableScan, fromAPI } from "@cur8/rich-entity";
import { APIClient } from "lib/api/client";
import { exhaustAnnotations } from "lib/api/resolvers/annotation";
import { groupBy } from "lib/groupBy";
import { Lesion, LesionLink } from "lib/lesion";
import { isRecordingURI } from "lib/uri/guard";
import { useEffect, useState } from "react";
import { useAPIClient } from "render/context/APIContext";

const MAX_ANNOTATIONS_FETCHED_PER_SCAN = 20000;

export function useLesions(scans?: ImmutableScan[]) {
  const api = useAPIClient();

  const [result, setResult] = useState<AsyncResult<Lesion[]>>();

  useEffect(() => {
    if (!scans) {
      return;
    }

    getLesions(api, scans).then((data) => {
      console.debug("Lesions", data);
      setResult({ data });
    });

    return () => {
      setResult(undefined);
    };
  }, [api, scans]);

  return result;
}

async function getLesions(
  api: APIClient,
  scans: ImmutableScan[]
): Promise<Lesion[]> {
  const all = await Promise.all([
    // lesion links from scans
    ...scans.map((scan) => getScanLesionLinks(api, scan)),
    // lesion links from derma annotations
    ...scans
      // all scans should belong to the same patientId, but this code makes no assumption
      .map((s) => s.patientId)
      .map((patientId) => getPatientLesionLinks(api, patientId)),
  ]);

  const grouped = groupBy(all.flat(), (l) => l.annotation.physicalArtefactId!);

  return Object.entries(grouped).map(([physicalId, links], index) => ({
    fakeId: index + 1,
    links,
    physicalId,
  }));
}

async function getScanLesionLinks(
  api: APIClient,
  scan: ImmutableScan
): Promise<LesionLink[]> {
  const [annotations, scores] = await Promise.all([
    fetchScanAnnotations(api, scan),
    fetchLesionRankingScores(api, scan),
  ]);
  return annotations.map((annotation) => {
    const id = annotation.physicalArtefactId;
    const rankingScore = id ? scores[id] : undefined;
    return { annotation, scan, rankingScore };
  });
}

async function getPatientLesionLinks(
  api: APIClient,
  patientId: string
): Promise<LesionLink[]> {
  const annotations = await fetchDermaAnnotations(api, patientId);
  return annotations.map((annotation) => ({ annotation }));
}

async function fetchDermaAnnotations(api: APIClient, patientId: string) {
  const annotations = await exhaustAnnotations(
    api.annotation,
    { patientId, applicationSpecificTarget: "derma:derma", pageSize: 100 },
    8000
  );
  return annotations.map(fromAPI.toAnnotation);
}

async function fetchScanAnnotations(
  api: APIClient,
  scan: ImmutableScan
): Promise<Annotation[]> {
  const hasLinkingResults =
    scan.resultStateSummary["linking_results"] === "complete";

  if (!hasLinkingResults) {
    return [];
  }

  const annotations = await api.scan
    .fetchLinkingResults({
      patientId: scan.patientId,
      scanId: scan.id,
      scanVersion: scan.version,
    })
    .result.then((annos) => {
      console.debug("Found linking_results for scan", scan);
      return annos.map((source) =>
        fromAPI.toAnnotation({
          ...source,
          createdAt: scan.timestamp.toISO(),
          updatedAt: scan.timestamp.toISO(),
          comment: "Hydrated from reduced",
          previewUrl: "",
          acceptState: APITypesV1.AcceptState.Proposed,
          audit: {},
        })
      );
    })
    .catch((error) => {
      console.warn("Failed fetching linking_results for scan", scan, error);
      return [];
    });

  const recordingURI = scan.sourceUris.find(isRecordingURI);

  if (!recordingURI) {
    return annotations;
  }

  const extras = await exhaustAnnotations(
    api.annotation,
    {
      patientId: scan.patientId,
      targetUri: recordingURI.toString(),
      acceptState: APITypesV1.AcceptState.Accepted,
      pageSize: 100,
    },
    MAX_ANNOTATIONS_FETCHED_PER_SCAN
  );

  return [...annotations, ...extras.map(fromAPI.toAnnotation)];
}

async function fetchLesionRankingScores(api: APIClient, scan: ImmutableScan) {
  const scores = await api.scan.fetchLesionRankingScores({
    patientId: scan.patientId,
    scanId: scan.id,
    scanVersion: scan.version,
  }).result;
  return scores.values;
}
