import { EcgQTC, Risk } from "@cur8/health-risks-calc";
import { Metric } from "lib/metric";
import { useMemo } from "react";
import { usePatientData } from "render/pages/DashboardPage/context/PatientDataContext";
import { RISK_COLORS } from "render/risk";
import { RampUpNumber } from "render/ui/format/RampUpNumber";
import { FloatingValueTarget } from "render/ui/presentation/FloatingValueTarget";
import { ValueItem } from "render/ui/symbol/ValueItem";
import { RampUpNumberAnimationConfig } from "../HeartEcgOverlay.animations";

interface EcgSegmentQTCProps {
  active?: boolean;
  qtc: Metric<"cardio.ecg.segments.qtc">[] | undefined;
  width?: number;
}

export function EcgSegmentQTC({
  active = true,
  qtc,
  width = 150,
}: EcgSegmentQTCProps) {
  const latest = qtc?.at(0);
  const { patient } = usePatientData();

  const risk = useMemo(() => {
    if (latest && patient) {
      return EcgQTC.rangesFor({ sex: patient.sex }).findRisk(latest.unit);
    }
  }, [latest, patient]);

  const color = RISK_COLORS[risk ?? Risk.Unknown];

  return (
    <FloatingValueTarget
      label={<>Reset</>}
      value={
        <ValueItem
          value={
            latest && active ? (
              <RampUpNumber
                value={latest.unit.ms}
                precision={0}
                duration={RampUpNumberAnimationConfig.duration}
                ease={RampUpNumberAnimationConfig.ease}
              />
            ) : (
              "--"
            )
          }
          unit="ms"
          size="huge"
        />
      }
      color={color}
      width={width}
    />
  );
}
