import { fromAPI, Site } from "@cur8/rich-entity";
import { silenceAbort } from "lib/error";
import { useEffect, useState } from "react";
import { useAPIClient } from "render/context/APIContext";

export function useSites() {
  const [sites, setSites] = useState<Site[]>();

  const api = useAPIClient().sites;

  useEffect(() => {
    const req = api.querySites();

    req.result
      .then((result) => result.items.map(fromAPI.toSite))
      .then(setSites)
      .catch(silenceAbort);

    return () => {
      setSites(undefined);
      req.abandon();
    };
  }, [api]);

  return sites;
}
