import { SVGAttributes, useEffect, useRef } from "react";
import { ArrowAnimationConfig } from "../../HeartEcgOverlay.animations";
import styles from "./styles.module.sass";

export interface ArrowProps extends SVGAttributes<SVGPathElement> {
  id: string;
  animate?: boolean;
}

export const Arrow = ({ id, d, animate = true }: ArrowProps) => {
  const lineRef = useRef<SVGPathElement>(null);
  const markerRef = useRef<SVGPathElement>(null);

  useEffect(() => {
    if (!animate) {
      return;
    }

    const marker = markerRef.current as SVGPathElement;
    const line = lineRef.current as SVGPathElement;

    if (!marker || !line) {
      return;
    }

    const lineLength = line.getTotalLength();

    const strokeDashFilled = lineLength * 0.75;
    const strokeDashEmpty = lineLength + (lineLength - strokeDashFilled);

    const lineDashStartOffset = strokeDashFilled;
    const lineDashMidOffset = strokeDashFilled - lineLength;
    const lineDashEndOffset = -lineLength;

    line.style.setProperty(
      "--stroke-dasharray",
      `${strokeDashFilled.toFixed(2)} ${strokeDashEmpty.toFixed(2)}`
    );
    line.style.setProperty(
      "--stroke-dashoffset",
      `${lineDashStartOffset.toFixed(2)}`
    );

    const lineAnimationKeyframes = ArrowAnimationConfig.keyframes.line({
      lineDashStartOffset,
      lineDashMidOffset,
      lineDashEndOffset,
    });
    const lineAnimation = line.animate(
      lineAnimationKeyframes,
      ArrowAnimationConfig.keyframeAnimationOptions.defaults
    );

    const markerAnimationKeyframes = ArrowAnimationConfig.keyframes.arrow();
    const markerAnimation = marker.animate(
      markerAnimationKeyframes,
      ArrowAnimationConfig.keyframeAnimationOptions.defaults
    );

    return () => {
      lineAnimation.cancel();
      markerAnimation.cancel();
    };
  }, [animate]);

  return (
    <g data-id={id} className={styles.Arrow} data-animate={animate}>
      <path
        ref={lineRef}
        d={d}
        data-role="line"
        strokeWidth="3"
        stroke="#019DC9"
      />
      <path
        ref={markerRef}
        style={{ offsetPath: `path('${d}')` }}
        data-role="marker"
        d="M11.2706 10.2837C11.7588 9.79557 11.7588 9.00411 11.2706 8.51596L3.31565 0.561007C2.8275 0.0728512 2.03604 0.0728512 1.54788 0.561007C1.05973 1.04916 1.05973 1.84062 1.54788 2.32877L8.61895 9.39984L1.54788 16.4709C1.05973 16.9591 1.05973 17.7505 1.54788 18.2387C2.03604 18.7268 2.8275 18.7268 3.31565 18.2387L11.2706 10.2837Z"
        fill="#019DC9"
      />
    </g>
  );
};
