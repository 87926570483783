import { cutSignal, Plot } from "lib/api/cardio";
import { normalize } from "lib/normalize";
import { useMemo } from "react";
import { CardioGraph } from "render/ui/graph/CardioGraph";
import { CardioGrid } from "render/ui/graph/CardioGrid";
import styles from "./styles.module.sass";

const DURATION = 5;
const HORIZONTALS = [(1 / 3) * 100 * 1, (1 / 3) * 100 * 2];
const VERTICALS = [20, 40, 60, 80];

interface GraphProps {
  plot: Plot;
}

export function Graph({ plot }: GraphProps) {
  const scaledPoints = useMemo(() => {
    const slice = cutSignal(plot, 0, DURATION);

    return slice.map(normalize(0, 1)).map((v, index) => {
      return {
        x: (index / slice.length) * 100,
        y: 5 + (1 - v) * 90,
      };
    });
  }, [plot]);

  const graphPoints = useMemo(() => {
    const points = [
      ...scaledPoints,
      { x: 200, y: 50 },
      { x: 100, y: 101 },
      { x: 0, y: 101 },
    ];

    return points;
  }, [scaledPoints]);

  return (
    <svg
      className={styles.Graph}
      viewBox="0 0 100 100"
      preserveAspectRatio="none"
    >
      <CardioGraph points={graphPoints} duration={DURATION} />

      <CardioGrid horizontal={HORIZONTALS} vertical={VERTICALS} />
    </svg>
  );
}
