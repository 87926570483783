import * as THREE from "three";

export function initTHREE() {
  THREE.Object3D.DEFAULT_UP.set(0, 0, 1);
}

export function toCenter(box: THREE.Box3) {
  const pos = new THREE.Vector3();
  box.getCenter(pos);
  return pos;
}

export function getCameraDistanceForViewportHeight(
  fov: number,
  targetViewportHeight: number
) {
  const fovRadians = (fov * Math.PI) / 180;
  const distance = targetViewportHeight / (2 * Math.tan(fovRadians / 2));

  return distance;
}

export function findObjectBFS(
  object: THREE.Object3D,
  predicate: (object: THREE.Object3D) => boolean
) {
  for (
    let nodes = [object], index = 0, node = nodes.at(index);
    node != null;
    node = nodes.at(++index)
  ) {
    for (const child of node.children) {
      if (predicate(child)) {
        return child;
      }
      nodes.push(child);
    }
  }

  return undefined;
}
