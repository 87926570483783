import { AvatarPodium } from "lib/avatar/podium";
import { useAvatarContext } from "render/pages/DashboardPage/context/AvatarContext";
import { Layer } from "render/pages/DashboardPage/types";
import { BodyLayer } from "./components/BodyLayer";
import { DiabetesLayer } from "./components/DiabetesLayer";

interface RenderLayersProps {
  layer: Layer;
  podium: AvatarPodium;
}

export function RenderLayers({ layer, podium }: RenderLayersProps) {
  const smpl = useAvatarContext().smpl;

  const areas = smpl.front.areas;

  if (!areas) {
    return null;
  }

  return (
    <>
      <BodyLayer active={layer === Layer.Body} areas={areas} podium={podium} />

      <DiabetesLayer
        active={layer === Layer.Diabetes}
        areas={areas}
        podium={podium}
      />
    </>
  );
}
