import { DashboardCamera } from "lib/avatar/camera";
import { AvatarPodium } from "lib/avatar/podium";
import { createContext, ReactNode, useContext, useMemo } from "react";
import { useAudioContext } from "./hooks/useAudioContext";
import { useCamera } from "./hooks/useCamera";
import { useHeartAudio } from "./hooks/useHeartAudio";
import { usePodium } from "./hooks/usePodium";
import { useUI } from "./hooks/useUI";

type DashboardContextValue = {
  camera: DashboardCamera;
  podium: AvatarPodium;
  ui: ReturnType<typeof useUI>;
  audioContext: AudioContext;
  heartAudio: ReturnType<typeof useHeartAudio>;
};

const Context = createContext<DashboardContextValue | null>(null);

interface DashboardContextProps {
  children: ReactNode;
}

export function DashboardContext({ children }: DashboardContextProps) {
  const ui = useUI();
  const camera = useCamera();
  const podium = usePodium();
  const heartAudio = useHeartAudio();
  const audioContext = useAudioContext();

  const contextValue = useMemo<DashboardContextValue>(() => {
    return { ui, camera, podium, audioContext, heartAudio };
  }, [ui, camera, podium, audioContext, heartAudio]);

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
}

export function useDashboardContext() {
  const context = useContext(Context);
  if (!context) {
    throw new Error("useDashboardContext without DashboardContext");
  }
  return context;
}
