export type Plot = {
  signal: number[];
  signal_length_sec: number;
  r_peak_indices?: number[];
};

export type PlotTimestamps = Plot & {
  timestamps: number[];
};

export function cutSignal(
  plot: Plot,
  sliceStartSec: number,
  sliceEndSec: number
) {
  const start = (plot.signal.length / plot.signal_length_sec) * sliceStartSec;
  const end = (plot.signal.length / plot.signal_length_sec) * sliceEndSec;

  return plot.signal.slice(start, end);
}
