import { ViewStack } from "@pomle/react-viewstack";
import { TextShifter } from "render/ui/presentation/TextShifter";
import { useDashboardContext } from "../../context/DashboardContext";
import { Layer } from "../../types";
import { AvatarSection } from "./components/AvatarSection";
import { ArterialHealthLayout } from "./layouts/ArterialHealthLayout";
import { ArterialStiffnessLayout } from "./layouts/ArterialStiffnessLayout";
import { BloodworkLayout } from "./layouts/BloodworkLayout";
import { BodyLayout } from "./layouts/BodyLayout";
import { CardioLayout } from "./layouts/CardioLayout";
import { CholesterolLayout } from "./layouts/CholesterolLayout";
import { CirculationLayout } from "./layouts/CirculationLayout";
import { DiabetesLayout } from "./layouts/DiabetesLayout";
import { DisclaimerLayout } from "./layouts/DisclaimerLayout";
import { HealthAspectMenu } from "./layouts/HealthAspectMenu";
import { HeartLayout } from "./layouts/HeartLayout";
import { IdentityLayout } from "./layouts/IdentityLayout";
import { LesionLayout } from "./layouts/LesionLayout";
import { LesionMacroLayout } from "./layouts/LesionMacroLayout";
import { NavigationLayout } from "./layouts/NavigationLayout";
import { SkinLayout } from "./layouts/SkinLayout";
import styles from "./styles.module.sass";

const INTRO_TEXTS = [
  "Collecting all health data",
  "Measuring biomarkers",
  "Analyzing findings",
  "",
] as const;

export function EntryPoint() {
  const { ui } = useDashboardContext();

  return (
    <div className={styles.EntryPoint}>
      <ViewStack>
        <section className={styles.avatar}>
          <AvatarSection layer={ui.layer} />
        </section>

        <section
          className={styles.title}
          data-active={ui.layer === Layer.Identity}
        >
          <div className={styles.scramble}>
            <TextShifter texts={INTRO_TEXTS} delay={2.4} />
          </div>
        </section>

        <section className={styles.layout}>
          <DisclaimerLayout />
        </section>

        <section className={styles.layout}>
          <HealthAspectMenu />
        </section>

        <section className={styles.layout}>
          <ArterialStiffnessLayout active={ui.layer === Layer.PulseWaves} />
        </section>

        <section className={styles.layout}>
          <CardioLayout active={ui.layer === Layer.Cardio} />
        </section>

        <section className={styles.layout}>
          <CholesterolLayout active={ui.layer === Layer.Cholesterol} />
        </section>

        <section className={styles.layout}>
          <BloodworkLayout active={ui.layer === Layer.Bloodwork} />
        </section>

        <section className={styles.layout}>
          <BodyLayout active={ui.layer === Layer.Body} />
        </section>

        <section className={styles.layout}>
          <DiabetesLayout active={ui.layer === Layer.Diabetes} />
        </section>

        <section className={styles.layout}>
          <CirculationLayout active={ui.layer === Layer.Circulation} />
        </section>

        <section className={styles.layout}>
          <ArterialHealthLayout active={ui.layer === Layer.ArterialHealth} />
        </section>

        <section className={styles.layout}>
          <HeartLayout active={ui.layer === Layer.Heart} />
        </section>

        <section className={styles.layout}>
          <SkinLayout active={ui.layer === Layer.Skin} />
        </section>

        <section className={styles.layout}>
          <LesionLayout active={ui.layer === Layer.LesionLibrary} />
        </section>

        <section className={styles.layout}>
          <LesionMacroLayout active={Layer.LesionMacro === ui.layer} />
        </section>

        <section className={styles.layout}>
          <IdentityLayout />
        </section>

        <section className={styles.layout}>
          <NavigationLayout />
        </section>
      </ViewStack>
    </div>
  );
}
