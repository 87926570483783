import { fromAPI, Room } from "@cur8/rich-entity";
import { silenceAbort } from "lib/error";
import { useEffect, useState } from "react";
import { useAPIClient } from "render/context/APIContext";

export function useSiteRooms(siteId: string) {
  const apiClient = useAPIClient();
  const [rooms, setRooms] = useState<AsyncResult<Room[]>>();

  useEffect(() => {
    const req = apiClient.room.queryRooms({ siteId });

    req.result
      .then((response) => {
        return response.items;
      })
      .then((rooms) => {
        return rooms.map(fromAPI.toRoom);
      })
      .then((data) => {
        setRooms({ data });
      })
      .catch(silenceAbort);

    return () => {
      req.abandon();
      setRooms(undefined);
    };
  }, [apiClient, siteId]);

  return {
    data: rooms?.data,
  };
}
