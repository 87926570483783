import { APITypesV1 } from "@cur8/api-client";
import { ImmutableScan } from "@cur8/rich-entity";
import { DateTime } from "luxon";

export const EMPTY_SECTIONS: APITypesV1.SummarySections = {
  introduction: "",
  heart: [""],
  body: [""],
  skin: [""],
  recommendations: [""],
};

export function isEmptySections(sections: APITypesV1.SummarySections) {
  if (
    sections.introduction === EMPTY_SECTIONS.introduction &&
    sections.heart === EMPTY_SECTIONS.heart &&
    sections.body === EMPTY_SECTIONS.body &&
    sections.skin === EMPTY_SECTIONS.skin &&
    sections.recommendations === EMPTY_SECTIONS.recommendations
  ) {
    return true;
  } else {
    return false;
  }
}

export const EMPTY_MEMBER_SUMMARY: APITypesV1.MemberSummary = {
  $type: "MemberSummary",
  transcription: undefined,
  results: "",
  summary: "",
  sections: EMPTY_SECTIONS,
};

export enum ScribeFlavor {
  Stable = "stable",
  Tuned = "tuned",
  None = "none",
}

export function ensureOne(input: string[]) {
  return input.length ? input : [""];
}

export function scanAgeMinutes(scan: ImmutableScan) {
  const now = DateTime.now();
  const diff = (now.toMillis() - scan.versionCreatedAt.toMillis()) / 1000 / 60;
  return diff;
}

export function hasSummaryComplete(scan: ImmutableScan): boolean {
  return (
    scan.resultStateSummary["sectionMemberSummary"] ===
    APITypesV1.ResultState.Complete
  );
}
