import { Plot } from "lib/api/cardio";
import { normalize } from "lib/normalize";
import { useMemo } from "react";
import { CardioGraph } from "render/ui/graph/CardioGraph";
import { CardioGrid } from "render/ui/graph/CardioGrid";
import { ECGGraphMarker } from "./components/ECGGraphMarker";
import {
  useECGGraphMarkers,
  type ECGGraphMarkerMetrics,
} from "./hooks/useECGGraphMarkers";
import styles from "./styles.module.sass";

interface ECGGraphProps {
  plot: Plot;
  metrics: ECGGraphMarkerMetrics;
  progress: number;
  debug?: boolean;
  active?: boolean;
  showMarkers?: boolean;
}

export function ECGGraph({
  plot,
  progress,
  metrics,
  debug = false,
  showMarkers = true,
}: ECGGraphProps) {
  const toX = useMemo(() => {
    return (index: number) => (index / plot.signal.length) * 100;
  }, [plot]);

  const toY = useMemo(() => {
    const normalizedSignal = plot.signal.map(normalize(0, 1));

    return (index: number) => {
      return 20 + (1 - normalizedSignal[index]) * 60;
    };
  }, [plot]);

  const scaledPoints = useMemo(() => {
    const slice = plot.signal;

    return slice.map((_, index) => {
      return {
        x: toX(index),
        y: toY(index),
      };
    });
  }, [plot, toX, toY]);

  const points = useMemo(() => {
    return [
      ...scaledPoints,
      { x: 200, y: 50 },
      { x: 100, y: 101 },
      { x: 0, y: 101 },
    ];
  }, [scaledPoints]);

  const peaksDebugLines = useMemo(() => {
    return (
      plot.r_peak_indices?.map((peakIndex) => {
        const x = (peakIndex / plot.signal.length) * 100;
        return x;
      }) ?? []
    );
  }, [plot]);

  const ecgMarkers = useECGGraphMarkers({
    plot,
    progress,
    metrics,
    toY,
    enabled: showMarkers,
  });

  return (
    <div className={styles.ECGGraph}>
      <CardioGrid vertical={[25, 50, 75]} horizontal={[0, 100]} />
      <CardioGraph points={points} progress={progress} />
      {debug && (
        <CardioGrid
          className={styles.debugGrid}
          horizontal={[]}
          vertical={peaksDebugLines}
        />
      )}
      <div className={styles.markers}>
        {ecgMarkers.p.data && (
          <ECGGraphMarker
            show={ecgMarkers.p.show}
            type="line"
            {...ecgMarkers.p.data}
          />
        )}
        {ecgMarkers.pr.data && (
          <ECGGraphMarker
            show={ecgMarkers.pr.show}
            type="frame"
            {...ecgMarkers.pr.data}
          />
        )}
        {ecgMarkers.qrs.data && (
          <ECGGraphMarker
            show={ecgMarkers.qrs.show}
            type="frame"
            {...ecgMarkers.qrs.data}
          />
        )}
        {ecgMarkers.qt.data && (
          <ECGGraphMarker
            show={ecgMarkers.qt.show}
            type="frame"
            {...ecgMarkers.qt.data}
          />
        )}
      </div>
    </div>
  );
}
