import { PadAssessmentBox, Patient } from "@cur8/rich-entity";
import { useEffect } from "react";
import { useAssessmentContext } from "../../context/AssessmentContext";
import { useAnnotation } from "./hooks/useAnnotation";
import { PadPage } from "./PadPage";

interface PadAnnotationLoaderProps {
  patient: Patient;
}

export function PadAnnotationLoader({ patient }: PadAnnotationLoaderProps) {
  const { createAssessment, moveRoI, selected } = useAssessmentContext();
  const { forearm, hypothenar, loading } = useAnnotation(
    patient.patientId,
    selected.scanId,
    selected.scanVersion
  );

  useEffect(() => {
    if ((selected as PadAssessmentBox).regionsOfInterest) {
      return;
    }
    // no selected ROI, use from annotations
    if (forearm && hypothenar) {
      moveRoI("forearm", forearm.annotation.data.rect);
      moveRoI("hypothenar", hypothenar.annotation.data.rect);
    }
  }, [forearm, hypothenar, moveRoI, selected]);

  if (loading) {
    return <h1>Fetching data...</h1>;
  }

  if (!forearm || !hypothenar) {
    return (
      <>
        <div>
          <h1>Failed to load data</h1>
          <p>
            Annotation is missing: <b>{!forearm ? "forearm" : "hypothenar"}</b>.
          </p>
          <p>
            If possible, annotate the scan with the missing region of interest.
            <br />
            Otherwise a new scan will be necessary.
          </p>
        </div>
      </>
    );
  }

  return (
    <PadPage
      patient={patient}
      createAssessment={createAssessment}
      selected={selected}
    />
  );
}
