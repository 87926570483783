import { APITypesV1 } from "@cur8/api-client";
import { Assessment, fromAPI, Visit } from "@cur8/rich-entity";
import { useCallback, useEffect, useState } from "react";
import { useAPIClient } from "render/context/APIContext";
import { useReporting } from "../useReporting";

/**
 * Return all assessments for given visit
 */
export function useVisitAssessments(visit: Visit) {
  const { handleError } = useReporting();
  const api = useAPIClient().assessment;
  const [assessments, setAssessments] = useState<Assessment[]>();
  const [unhandled, setUnhandled] = useState<Assessment[]>();

  const fetch = useCallback(() => {
    api
      .queryAssessments({
        patientId: visit.patientId,
        visitId: visit.visitId,
        isLatestVersion: true,
      })
      .result.then((aa) => {
        const ac: Assessment[] = [];
        aa.items.forEach((a) => {
          ac.push(fromAPI.toAssessment(a));
        });
        setAssessments(ac);
        setUnhandled(
          ac.filter(
            (ass) => ass.assessmentState === APITypesV1.AssessmentState.New
          )
        );
      })
      .catch(handleError);
  }, [api, handleError, visit]);

  useEffect(() => {
    if (!visit.patientId || !visit.visitId) {
      return;
    }
    fetch();
  }, [fetch, visit.patientId, visit.visitId]);

  return {
    assessments,
    unhandled,
  };
}
