import { ImmutableVisitSummary } from "@cur8/rich-entity";
import { MouseEvent, useCallback, useMemo, useState } from "react";
import ReactMarkdown from "react-markdown";
import { Checkmark } from "render/ui/symbol/Checkmark";
import { Chevron } from "render/ui/symbol/Chevron";
import { HoverTextButton } from "render/ui/trigger/HoverTextButton";
import styles from "../../styles.module.sass";
import { UserDisplayName } from "./UserDisplayName";

interface VisitSummaryRowProps {
  patientId: string;
  openModal: (
    patientId: string,
    visitId: string,
    type: "edit" | "create"
  ) => void;
  visitId: string;
  visitSummary?: ImmutableVisitSummary;
}

export function VisitSummaryRow({
  patientId,
  openModal,
  visitId,
  visitSummary,
}: VisitSummaryRowProps) {
  const [isOpen, setIsOpen] = useState(false);
  const userUri = useMemo(() => {
    if (!visitSummary) {
      return undefined;
    }
    const uri = visitSummary.audit.created?.userUri;
    if (!uri || uri.toString().startsWith("cur8:user:worker:")) {
      // TODO: use the cur8/uri package. cc: Paul
      return undefined;
    } else {
      return uri;
    }
  }, [visitSummary]);

  const lastModified =
    visitSummary?.audit.lastModified?.timeStamp ??
    visitSummary?.audit.created?.timeStamp;

  const hasSummary = visitSummary ? true : false;

  const toggleOpen = useCallback(() => {
    if (!hasSummary) {
      return;
    }
    setIsOpen((op) => !op);
  }, [hasSummary]);

  const openEditor = useCallback(
    (ev: MouseEvent) => {
      ev.stopPropagation();
      openModal(patientId, visitId, hasSummary ? "edit" : "create");
    },
    [hasSummary, patientId, openModal, visitId]
  );

  return (
    <>
      <tr
        onClick={toggleOpen}
        className={styles.clickable}
        data-clickable={hasSummary}
      >
        <td className={styles.icon}>
          <Checkmark type={visitSummary ? "filled" : "unchecked"} />
        </td>
        <td className={styles.name}>Summary</td>
        <td>{visitSummary ? "Sent" : "Not created"}</td>
        <td>{userUri && <UserDisplayName userUri={userUri} />}</td>
        <td className={styles.date}>
          {lastModified && (
            <time dateTime={lastModified.toISO()}>
              {lastModified.toFormat("yyyy-LL-dd HH:mm")}
            </time>
          )}
        </td>
        <td></td>
        <td className={styles.action}>
          <div>
            <HoverTextButton onClick={openEditor}>
              {hasSummary ? "Open" : "Create"}
            </HoverTextButton>
            {visitSummary && <Chevron direction={isOpen ? "up" : "down"} />}
          </div>
        </td>
      </tr>
      {isOpen && (
        <tr>
          <td colSpan={7} className={styles.summary}>
            <div>
              <ReactMarkdown>{visitSummary?.summaryText}</ReactMarkdown>
            </div>
          </td>
        </tr>
      )}
    </>
  );
}
