import { Audio, AudioPlayer } from "lib/audio/player";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDashboardContext } from "render/pages/DashboardPage/context/DashboardContext";

export enum PlaybackState {
  Paused = "paused",
  Playing = "playing",
}

export type PlaybackOptions = {
  loop?: Audio["loop"];
  muted?: AudioPlayer["muted"];
};

export type AudioPlaybackControls = ReturnType<typeof useAudioPlayback>;

export function useAudioPlayback(
  id: string,
  audioBuffer: AudioBuffer | undefined,
  { muted = false, loop = false }: PlaybackOptions
) {
  const { audioContext } = useDashboardContext();
  const [audioPlayer, setAudioPlayer] = useState<AudioPlayer>();
  const [playbackState, setPlaybackState] = useState(PlaybackState.Paused);
  const [playbackStartTime, setPlaybackStartTime] = useState<number | null>();
  const [playbackCurrentTime, setPlaybackCurrentTime] = useState(0);
  const duration = useMemo(() => audioBuffer?.duration ?? NaN, [audioBuffer]);
  const progress = useMemo(() => {
    if (!duration) {
      return NaN;
    }
    return (playbackCurrentTime % duration) / duration;
  }, [playbackCurrentTime, duration]);

  useEffect(() => {
    const audioPlayer = new AudioPlayer({ ctx: audioContext });
    setAudioPlayer(audioPlayer);

    return () => {
      setAudioPlayer(undefined);
      audioPlayer.dispose();
    };
  }, [audioContext]);

  useEffect(() => {
    audioPlayer?.mute(muted);
  }, [audioPlayer, muted]);

  const audio: Audio | undefined = useMemo(() => {
    if (!audioBuffer) {
      return;
    }
    return new Audio({
      id,
      buffer: audioBuffer,
      loop,
    });
  }, [id, audioBuffer, loop]);

  const play = useCallback(() => {
    if (!audio || !audioPlayer) {
      return;
    }

    audioPlayer.play(audio).then(() => {
      setPlaybackStartTime(audioContext.currentTime);
      setPlaybackState(PlaybackState.Playing);
    });
  }, [audioPlayer, audioContext, audio]);

  const pause = useCallback(() => {
    if (!audio || !audioPlayer) {
      return;
    }

    audioPlayer.stop(audio);

    setPlaybackState(PlaybackState.Paused);
    setPlaybackStartTime(undefined);
  }, [audio, audioPlayer]);

  const resume = useCallback(() => {
    if (playbackState === "playing") {
      return;
    }

    play();
  }, [play, playbackState]);

  const toggle = useCallback(() => {
    if (playbackState === "playing") {
      pause();
    } else {
      play();
    }
  }, [playbackState, play, pause]);

  useEffect(() => {
    if (playbackState !== "playing" || !playbackStartTime) {
      return;
    }

    let id = -1;

    const update = () => {
      id = requestAnimationFrame(update);
      setPlaybackCurrentTime(audioContext.currentTime - playbackStartTime);
    };

    update();

    return () => {
      cancelAnimationFrame(id);
    };
  }, [audioContext, playbackState, playbackStartTime]);

  return {
    play,
    pause,
    resume,
    toggle,
    playbackState,
    playbackCurrentTime,
    duration,
    progress,
  };
}
