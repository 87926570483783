import { codecs, Path } from "@pomle/paths";

export function createPaths<T extends {}>(origin: Path<T>) {
  const create = origin.append("/create-visit-summary/:visitId", {
    visitId: codecs.string,
  });

  const edit = origin.append("/edit-visit-summary/:visitId", {
    visitId: codecs.string,
  });

  const followup = origin.append("/followup/:visitId", {
    visitId: codecs.string,
  });

  const followupEdit = origin.append("/followup/:visitId/:notificationId", {
    visitId: codecs.string,
    notificationId: codecs.string,
  });

  return { create, edit, followup, followupEdit };
}
