import { useCallback } from "react";
import { usePopupDialog } from "render/context/PopupDialogContext";
import { FeedbackModal } from "./FeedbackModal";

export function useFeedbackDialog() {
  const { create } = usePopupDialog();

  const emitFeedbackDialog = useCallback(
    (
      onSend: ({
        feedback,
        isLiked,
      }: {
        feedback: string;
        isLiked: boolean;
      }) => void
    ) => {
      const { emit, close } = create();
      emit(
        <FeedbackModal
          content={"What did you think about the generated summary"}
          onOK={({
            feedback,
            isLiked,
          }: {
            feedback: string;
            isLiked: boolean;
          }) => {
            onSend({ feedback, isLiked });
            close();
          }}
          onCancel={close}
        />
      );
    },
    [create]
  );

  return {
    emitFeedbackDialog,
  };
}
