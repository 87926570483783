import { convertQuestionnaire } from "lib/questionnaire/convert";
import { getCountryFromVisit } from "lib/visit/visit";
import { createContext, ReactNode, useContext, useMemo } from "react";
import { useABIScore } from "render/hooks/api/metrics/useABIScore";
import { useScore2 } from "render/hooks/api/metrics/useScore2";
import { useTBIScore } from "render/hooks/api/metrics/useTBIScore";
import { usePatient } from "render/hooks/api/usePatient";
import { usePatientImmutableScans } from "render/hooks/api/usePatientImmutableScans";
import { usePatientSMPLMetrics } from "render/hooks/api/usePatientSMPLMetrics";
import { useQuestionnaire } from "render/hooks/api/useQuestionnaire";
import { useVisit } from "render/hooks/api/useVisit";
import { useVisitHistory } from "render/hooks/api/useVisitHistory";
import { usePatientVisitsMetrics } from "render/hooks/patient/usePatientVisitsMetrics";
import { useLiveTime } from "render/hooks/useLiveTime";
import { useLesions } from "./hooks/lesions";

function useDashboardData(patientId: string) {
  const now = useLiveTime("minute");

  const patient = usePatient(patientId);

  const visitHistory = useVisitHistory({ patientId });

  const latestVisit = useVisit({
    patientId,
    visitId:
      visitHistory?.medicalExamVisitTimeSeries.nekoBodyScan?.at(0)?.visitId,
  });

  const pastThreeAttendedBodyScanVisitIds = useMemo(() => {
    return visitHistory?.medicalExamVisitTimeSeries.nekoBodyScan
      ?.slice(0, 3)
      .map(({ visitId }) => visitId);
  }, [visitHistory?.medicalExamVisitTimeSeries.nekoBodyScan]);

  const visitMetrics = usePatientVisitsMetrics(
    patientId,
    pastThreeAttendedBodyScanVisitIds
  );

  const scans = usePatientImmutableScans(patientId);

  const metrics = visitMetrics;
  const questionnaire = useQuestionnaire({ patientId, visitId: undefined });
  const lifestyle = useMemo(() => {
    if (questionnaire.data) {
      return convertQuestionnaire(questionnaire.data);
    }
  }, [questionnaire.data]);

  const smplResult = usePatientSMPLMetrics(scans?.skin);

  const aggregates = {
    score2: useScore2({
      patient,
      date: now,
      brachial: metrics.cardio.brachialPressure,
      isSmoker: lifestyle?.isSmoker,
      nonHDL: metrics.bloodwork.nonHDL,
    }),

    abi: {
      left: useABIScore("left", metrics.cardio),
      right: useABIScore("right", metrics.cardio),
    },

    tbi: useTBIScore(metrics.cardio),
  };

  const lesionsResult = useLesions(scans?.skin);

  const country = useMemo(() => {
    if (latestVisit == null) {
      return undefined;
    }

    return getCountryFromVisit(latestVisit);
  }, [latestVisit]);

  return {
    skin: {
      lesions: lesionsResult?.data,
    },
    country,
    patientId,
    patient,
    scans,
    metrics,
    aggregates,
    lifestyle,
    questionnaire,
    smpl: {
      metrics: smplResult?.data,
    },
  };
}

type PatientContextValue = ReturnType<typeof useDashboardData>;

const Context = createContext<PatientContextValue | null>(null);

interface PatientDataContextProps {
  patientId: string;
  children: ReactNode;
}

export function PatientDataContext({
  patientId,
  children,
}: PatientDataContextProps) {
  const value = useDashboardData(patientId);

  return <Context.Provider value={value}>{children}</Context.Provider>;
}

export function usePatientData() {
  const context = useContext(Context);
  if (!context) {
    throw new Error("usePatientData without PatientContext");
  }
  return context;
}
