import { ListItemNode, ListNode } from "@lexical/list";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { LexicalErrorBoundary } from "@lexical/react/LexicalErrorBoundary";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { EditorPlugins } from "./components/plugins/EditorPlugins";
import styles from "./MarkdownEditor.module.sass";

export interface MarkdownEditorProps {
  value: string;
  onChange: (value: string) => void;
  placeholder: string;
}

export function MarkdownEditor({
  value,
  onChange,
  placeholder,
}: MarkdownEditorProps) {
  const initialConfig = {
    namespace: "MarkdownEditor",
    theme: {},
    onError: (error: Error) => {
      throw error;
    },
    nodes: [ListNode, ListItemNode],
  };

  return (
    <div className={styles.MarkdownEditor}>
      <div className={styles.editorContainer}>
        <LexicalComposer initialConfig={initialConfig}>
          <RichTextPlugin
            contentEditable={
              <ContentEditable className={styles.contentEditable} />
            }
            placeholder={
              <div className={styles.placeholder}>{placeholder}</div>
            }
            ErrorBoundary={LexicalErrorBoundary}
          />
          <EditorPlugins value={value} onChange={onChange} />
        </LexicalComposer>
      </div>
    </div>
  );
}
