import { ReactNode, useMemo } from "react";
import { Entry } from "render/ui/presentation/MeasurementTimeline";
import { MainTitle, MetricResultHeader, Titles } from "../MetricResultHeader";
import {
  AuxTitle,
  Diff,
  DiffValue,
} from "../MetricResultHeader/MetricResultHeader";
import { RangeChart, RangeChartDataComposer } from "../RangeChart";
import { ChartRange } from "../RangeChart/types";

interface MetricRangeProps {
  title: ReactNode;
  subtitle?: ReactNode;
  unit?: ReactNode;
  ranges?: ChartRange[];
  entries?: Entry[];
  formatValue?: (value?: number) => string | number | undefined;
  showChange?: boolean;
}

export function MetricRange({
  title,
  subtitle,
  unit,
  ranges,
  entries,
  formatValue = (n?: number) => n,
  showChange = true,
}: MetricRangeProps) {
  const latestEntry = entries?.at(0);
  const latestValue = useMemo(() => {
    return latestEntry?.repr != null
      ? Number(latestEntry?.repr)
      : latestEntry?.value;
  }, [latestEntry]);

  const previousEntry = entries?.at(1);
  const previousValue = useMemo(() => {
    return previousEntry?.repr != null
      ? Number(previousEntry?.repr)
      : previousEntry?.value;
  }, [previousEntry]);

  const valuesDiff = useMemo(() => {
    return latestValue != null && previousValue != null
      ? latestValue - previousValue
      : undefined;
  }, [latestValue, previousValue]);

  return (
    <div>
      <MetricResultHeader>
        <Titles>
          <MainTitle>{title}</MainTitle>
          {subtitle && <AuxTitle>{subtitle}</AuxTitle>}
        </Titles>
        <Diff>
          <DiffValue
            valuesDiff={showChange ? valuesDiff : undefined}
            formatValue={formatValue}
            unit={unit?.toString()}
          />
        </Diff>
      </MetricResultHeader>
      {ranges && (
        <RangeChartDataComposer
          ranges={ranges}
          value={latestValue}
          previousValue={showChange ? previousValue : undefined}
          formatValue={formatValue}
        >
          {({ ranges, values }) => (
            <RangeChart ranges={ranges} values={values} />
          )}
        </RangeChartDataComposer>
      )}
    </div>
  );
}
