export interface FunctionCall {
  arguments: string;
  name: string;
}

export type Role = "system" | "user" | "assistant";

export interface Message {
  role: Role;
  content?: string;
  function_call?: FunctionCall;
}

export interface ChatConfig {
  name: string;
  route: string;
  firstPrompt: string;
}

export interface SpeechToken {
  authToken: string;
  region: string;
}

export enum Language {
  English = "english",
  Swedish = "swedish",
}

export enum Model {
  GPT35Turbo16k = "gpt-35-turbo-16k",
  GPT4 = "gpt-4",
  GPT4o = "gpt-4o",
  GPT4Turbo = "gpt-4-turbo",
  GPT432k = "gpt-4-32k",
}

export interface Settings {
  language: Language;
  model: Model;
  temp: number;
}
