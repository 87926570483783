import styles from "./styles.module.sass";

type Direction = "left" | "right" | "up" | "down";

export function Chevron({ direction = "down" }: { direction?: Direction }) {
  return (
    <svg
      className={styles.Chevron}
      data-dir={direction}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M3 5.5L8 10.5L13 5.5" />
    </svg>
  );
}
