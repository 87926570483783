import { EcgPR, EcgQRS, EcgQTC, highestRisk } from "@cur8/health-risks-calc";
import { Patient } from "@cur8/rich-entity";
import { useMemo } from "react";
import { PatientMetrics } from "render/hooks/patient/usePatientMetrics";
import { MetricStatus } from "render/ui/presentation/MetricStatus";
import { MetricStatusBadge } from "render/ui/presentation/MetricStatusBadge";
import HeartRhythmIcon from "./assets/heart-rhythm.svg?react";

export type HeartRhythmMetrics = Pick<
  PatientMetrics["cardio"]["ecg"],
  "pr" | "qrs" | "qtc"
>;

type HeartRhythmItemProps = HeartRhythmMetrics & {
  patient: Patient;
};
export function HeartRhythmItem({
  patient,
  pr,
  qrs,
  qtc,
}: HeartRhythmItemProps) {
  const latestPR = pr?.at(0);
  const latestQRS = qrs?.at(0);
  const latestQTC = qtc?.at(0);

  const risk = useMemo(() => {
    if (!latestPR || !latestQRS || !latestQTC) {
      return;
    }

    const risks = [
      EcgPR.rangesFor().findRisk(latestPR.unit),
      EcgQRS.rangesFor().findRisk(latestQRS.unit),
      EcgQTC.rangesFor({ sex: patient.sex }).findRisk(latestQTC.unit),
    ];

    return highestRisk(risks);
  }, [patient, latestPR, latestQRS, latestQTC]);

  return (
    <MetricStatus
      caption={<>Heart rhythm</>}
      summaryContent={risk != null ? <MetricStatusBadge risk={risk} /> : "--"}
    >
      <HeartRhythmIcon />
    </MetricStatus>
  );
}
