import { APITypesV1 } from "@cur8/api-client";
import { BoundingBoxAnnotation, fromAPI } from "@cur8/rich-entity";
import { ScanURI } from "@cur8/uri";
import { useCallback, useEffect, useState } from "react";
import { useAPIClient } from "render/context/APIContext";
import { TissueAnnotation } from "../lib/types";
import { toTissueAnnotation } from "../lib/utils";

export function useAnnotation(
  patientId: string | undefined,
  scanId: string | undefined,
  scanVersion: string | undefined
) {
  const annoApi = useAPIClient().annotation;

  const [loading, setLoading] = useState(true);
  const [forearm, setForearm] =
    useState<TissueAnnotation<BoundingBoxAnnotation>>();
  const [hypothenar, setHypothenar] =
    useState<TissueAnnotation<BoundingBoxAnnotation>>();

  useEffect(() => {
    return () => {
      setForearm(undefined);
      setHypothenar(undefined);
    };
  }, [scanId, scanVersion]);

  const fetch = useCallback(async () => {
    if (!patientId || !scanId || !scanVersion) {
      return;
    }

    setLoading(true);
    const { items } = await annoApi.queryAnnotations({
      patientId,
      targetUri: new ScanURI(patientId, scanId, scanVersion).toString(),
      pageSize: 100,
      order: APITypesV1.SortOrder.Asc,
    }).result;
    setLoading(false);

    const annotations = items
      .map(fromAPI.toAnnotation)
      .filter(
        (a) =>
          a.acceptState === APITypesV1.AcceptState.Accepted ||
          a.acceptState === APITypesV1.AcceptState.Proposed
      )
      .map((a) => toTissueAnnotation(a, 0));

    setForearm(annotations.find((a) => a.label === "forearm"));
    setHypothenar(annotations.find((a) => a.label === "hypothenar"));
  }, [annoApi, patientId, scanId, scanVersion]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return {
    forearm,
    fetch,
    hypothenar,
    loading,
  };
}
