import { APITypesV1 } from "@cur8/api-client";
import { fromAPI, ScheduleViewSlot, Visit } from "@cur8/rich-entity";
import { classNames } from "@pomle/classnames";
import { DateTime, Interval } from "luxon";
import { useEffect, useMemo, useState } from "react";
import { useAPIClient } from "render/context/APIContext";
import { SlotTime } from "./fragments/SlotTime";
import styles from "./styles.module.sass";

function toInterval(slot: ScheduleViewSlot): Interval {
  return Interval.fromDateTimes(slot.startTime, slot.endTime);
}

interface Props {
  slot: ScheduleViewSlot;
  isAppointmentsCensored: boolean;
}

const NotHappenedVisitStatus = new Set([
  APITypesV1.VisitStatus.Cancelled,
  APITypesV1.VisitStatus.NoShow,
]);

export function BookedSlot({ slot, isAppointmentsCensored }: Props) {
  const apiClient = useAPIClient();
  const patientId = slot.patientId;
  const end = slot.endTime.toISO();
  const [previousVisits, setPreviousVisits] = useState<Visit[]>();

  useEffect(() => {
    if (!patientId || isAppointmentsCensored) {
      return;
    }

    const req = apiClient.visit.getPatientVisits(
      { patientId },
      {
        pageSize: 100,
        order: APITypesV1.SortOrder.Desc,
        endDateTimeOffsetRange: { end },
      }
    );

    req.result
      .then((response) => response.items.map(fromAPI.toVisit))
      .then(setPreviousVisits);

    return () => {
      setPreviousVisits(undefined);
      req.abandon();
    };
  }, [patientId, apiClient, end, isAppointmentsCensored]);

  const previousVisitCount = previousVisits?.filter(
    (visit) => visit.status && !NotHappenedVisitStatus.has(visit.status)
  ).length;

  const backgroundStyles = useMemo(() => {
    const now = DateTime.now();
    if (slot.startTime > now) {
      return styles.future;
    }
    if (slot.endTime < now) {
      return styles.past;
    }

    return styles.present;
  }, [slot.startTime, slot.endTime]);

  if (!slot.id) {
    return <div className={styles.busy}></div>;
  }

  const showOnboardingNotCompleted =
    slot.patientDetails &&
    !slot.patientDetails.finishedOnboarding &&
    !isAppointmentsCensored;

  const showIdentityNotVerified =
    slot.patientDetails?.hasVerifiedIdentity === false &&
    !isAppointmentsCensored;

  return (
    <div className={classNames(styles.busy, styles.slot, backgroundStyles)}>
      <div className={styles.notifications}>
        <div
          className={styles.onboardingAttention}
          data-show={showOnboardingNotCompleted}
          title="Onboarding not completed"
        />
        <div
          className={styles.identityAttention}
          data-show={showIdentityNotVerified}
          title="Identity not verified"
        />
      </div>
      <div className={styles.title}>
        {isAppointmentsCensored || !slot.patientDetails?.displayName
          ? "Booked"
          : slot.patientDetails.displayName}
      </div>
      <div className={styles.subtitle}>
        {isAppointmentsCensored ? (
          "Appointment"
        ) : (
          <SlotTime timeWindow={toInterval(slot)} />
        )}
      </div>
      {previousVisitCount != null && !isAppointmentsCensored && (
        <div className={styles.subtitle}>
          {previousVisitCount === 0 && <div>Baseline</div>}
          {previousVisitCount !== 0 && (
            <div>Follow up [{previousVisitCount}]</div>
          )}
        </div>
      )}
    </div>
  );
}
