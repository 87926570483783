export type PrePrompt = {
  title: string;
  prompt: string;
};

export const prePrompts: PrePrompt[] = [
  {
    title: "Shorter",
    prompt: "Make the summary overall shorter",
  },
  {
    title: "Add GP referral",
    prompt: "Add to the introduction that a referral to a GP will be made.",
  },
  {
    title: "Add BP device",
    prompt:
      "Add to the introduction that the patient will get a take-home 24h blood pressure device.",
  },
];
