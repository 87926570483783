import { APITypesV1 } from "@cur8/api-client";
import { BulletSectionEditor } from "../BulletSectionEditor";
import { PlaintextSectionEditor } from "../PlaintextSectionEditor";

interface SummaryEditorProps {
  sections: APITypesV1.SummarySections;
  onChange: (newSections: APITypesV1.SummarySections) => void;
}

export default function SummaryEditor({
  sections,
  onChange,
}: SummaryEditorProps) {
  return (
    <>
      <PlaintextSectionEditor
        title="Overview"
        placeholder="Write a short summary"
        content={sections.introduction}
        onChange={(v) => onChange({ ...sections, introduction: v })}
      />
      <BulletSectionEditor
        title="Heart & circulation"
        content={sections.heart}
        onChange={(v) => onChange({ ...sections, heart: v })}
      />
      <BulletSectionEditor
        title="Body"
        content={sections.body}
        onChange={(v) => onChange({ ...sections, body: v })}
      />
      <BulletSectionEditor
        title="Skin"
        content={sections.skin}
        onChange={(v) => onChange({ ...sections, skin: v })}
      />
      <BulletSectionEditor
        title="Recommendations"
        content={sections.recommendations}
        onChange={(v) => onChange({ ...sections, recommendations: v })}
      />
    </>
  );
}
