import { Patient } from "@cur8/rich-entity";
import { useEffect, useMemo, useState } from "react";
import { HEART_ECG_OVERLAY_REVEAL_DELAY_MS } from "render/fragments/avatar/overlay/HeartEcgOverlay/HeartEcgOverlay.animations";
import { EcgPRRange } from "render/fragments/metric/range-charts/EcgPRRange";
import { EcgQRSRange } from "render/fragments/metric/range-charts/EcgQRSRange";
import { EcgQTCRange } from "render/fragments/metric/range-charts/EcgQTCRange";
import { useDelay } from "render/hooks/useDelay";
import { usePatientData } from "render/pages/DashboardPage/context/PatientDataContext";
import { Typography } from "render/ui/presentation/Typography";
import { AudioGraph } from "./components/AudioGraph";
import { ECGGraph } from "./components/ECGGraph";
import { useAudioAmplitude } from "./hooks/useAudioAmplitude";
import { useAudioBuffer } from "./hooks/useAudioBuffer";
import { useAudioBufferSlice } from "./hooks/useAudioBufferSlice";
import { useAudioPlayback } from "./hooks/useAudioPlayback";
import { useECGPlotSlice } from "./hooks/useECGPlotSlice";
import { useECGSyncedPlot } from "./hooks/useECGSynced";
import { useHeartSound } from "./hooks/useHeartSound";
import styles from "./styles.module.sass";

const EMPTY: number[] = [];
const ECG_SIGNAL_SLICE_CONFIG = {
  HEARTBEAT_COUNT: 4,
  BUFFER_START_SEC: 0.5,
  BUFFER_END_SEC: 0.5,
} as const;

interface HeartPanelProps {
  patient: Patient;
  active: boolean;
  muted?: boolean;
  debug?: boolean;
}

export function HeartPanel({
  active,
  muted = true,
  debug = false,
}: HeartPanelProps) {
  const {
    patient,
    scans,
    metrics: { cardio },
  } = usePatientData();

  const scanStoringAudio = useMemo(() => {
    if (scans == null) {
      return undefined;
    }
    return scans.cardio.at(0) ?? scans.stethoscope.at(0);
  }, [scans]);

  const heartSound = useHeartSound(scanStoringAudio);
  const ecgSyncedToHeartSound = useECGSyncedPlot(scanStoringAudio);

  const {
    slice: ecgPlotSlice,
    sliceStartSec,
    sliceEndSec,
  } = useECGPlotSlice(ecgSyncedToHeartSound, {
    heartbeats: ECG_SIGNAL_SLICE_CONFIG.HEARTBEAT_COUNT,
    bufferStartSec: ECG_SIGNAL_SLICE_CONFIG.BUFFER_START_SEC,
    bufferEndSec: ECG_SIGNAL_SLICE_CONFIG.BUFFER_END_SEC,
  });

  const audioBufferOriginal = useAudioBuffer(heartSound);
  const audioBufferSlice = useAudioBufferSlice(audioBufferOriginal, {
    sliceStartSec,
    sliceEndSec,
  });

  const audioSignal = useAudioAmplitude(audioBufferSlice);
  const { play, pause, resume, progress } = useAudioPlayback(
    "heart_sound_audio",
    audioBufferSlice,
    { loop: true, muted }
  );

  const audioProgress = progress || 0;

  useEffect(() => {
    active ? play() : pause();
  }, [active, pause, play]);

  /**
   * ECG Graph marker reveal should be synced with the heart overlay reveal.
   * The heart overlay starts animating on a fixed delay after the heart view becomes active.
   * Simultaneously, the ECG signal graph becomes active and starts looping
   * The original ECG signal is sliced to 4 heartbeats for visualization purposes ...
   * so the signal loop length varies based on the member's heart rate.
   * In order to sync the heart overlay reveal and the ECG graph markers reveal:
   * - we should wait for the heart overlay reveal to start ...
   * - and then wait for the ECG signal to start a new loop (progress === 0) before revealing the markers.
   */
  const [showEcgMarkers, setShowEcgMarkers] = useState(false);
  const isHeartEcgOverlayRevealed = useDelay({
    active,
    delay: Math.max(0, HEART_ECG_OVERLAY_REVEAL_DELAY_MS - 100),
  });

  useEffect(() => {
    if (
      !showEcgMarkers &&
      isHeartEcgOverlayRevealed &&
      Math.round(audioProgress) === 0
    ) {
      setShowEcgMarkers(true);
    }
  }, [showEcgMarkers, audioProgress, isHeartEcgOverlayRevealed]);

  return (
    <div className={styles.HeartPanel}>
      <Typography as="h2" variant="display-s">
        Heart rhythm
      </Typography>

      <div className={styles.graphs} onClick={resume}>
        {ecgPlotSlice && (
          <div className={styles.graph}>
            <ECGGraph
              debug={debug}
              plot={ecgPlotSlice}
              progress={audioProgress}
              metrics={cardio.ecg}
              showMarkers={showEcgMarkers}
            />
          </div>
        )}

        {debug && (
          <div className={styles.graph}>
            <AudioGraph
              volume={audioSignal ?? EMPTY}
              progress={audioProgress}
            />
          </div>
        )}

        <div className={styles.rangeCharts}>
          <EcgPRRange metrics={cardio.ecg.pr} />
          <EcgQRSRange metrics={cardio.ecg.qrs} />
          {patient && (
            <EcgQTCRange patient={patient} metrics={cardio.ecg.qtc} />
          )}
        </div>
      </div>
    </div>
  );
}
