import styles from "./styles.module.sass";

interface ParagraphProps {
  children: JSX.Element;
  isBullet: boolean;
}

export default function Paragraph({ children, isBullet }: ParagraphProps) {
  return (
    <div className={styles.Paragraph}>
      {isBullet && <div className={styles.squareBullet}></div>}
      <div className={styles.content}>{children}</div>
    </div>
  );
}
