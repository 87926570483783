import { Patient } from "@cur8/rich-entity";
import { FLAGS, Flags } from "lib/flags";
import { CRPRange } from "render/fragments/metric/range-charts/CRPRange";
import { ArterialHealthItem } from "render/fragments/metric/summary/ArterialHealthItem";
import { BloodFatsItem } from "render/fragments/metric/summary/BloodFatsItem";
import { HeartRhythmItem as HeartRhythmItem2 } from "render/fragments/metric/summary/HeartRhythmItem2";
import { HeartRythmItem } from "render/fragments/metric/summary/HeartRythmItem";
import { useHighestBrachialPressure } from "render/hooks/api/metrics/useHighestBrachialPressure";
import { useDashboardContext } from "render/pages/DashboardPage/context/DashboardContext";
import { usePatientData } from "render/pages/DashboardPage/context/PatientDataContext";
import { Layer } from "render/pages/DashboardPage/types";
import { SummarySet } from "render/pages/DashboardPage/ui/SummarySet";
import { Typography } from "render/ui/presentation/Typography";
import { HeartAgeSection } from "./components/HeartAgeSection";
import styles from "./styles.module.sass";

interface CardioSectionProps {
  patient: Patient;
}

export function CardioSection({ patient }: CardioSectionProps) {
  const { ui } = useDashboardContext();

  const {
    metrics: { cardio, bloodwork, risk },
    aggregates: { abi, score2 },
  } = usePatientData();

  const highestBrachial = useHighestBrachialPressure(cardio.brachialPressure);

  return (
    <div className={styles.CardioSection}>
      <Typography as="h2" variant="display-s">
        Heart & Circulation
      </Typography>

      <hr />

      <div className={styles.heartAgeSection}>
        <HeartAgeSection patient={patient} />
      </div>

      <hr />

      <SummarySet>
        <button
          type="button"
          onClick={() => {
            ui.set({ layer: Layer.ArterialHealth });
          }}
        >
          <ArterialHealthItem
            patient={patient}
            abi={abi}
            highestBrachial={highestBrachial?.unit}
          />
        </button>

        <button
          type="button"
          onClick={() => {
            ui.set({ layer: Layer.Heart });
          }}
        >
          {FLAGS.has(Flags.Cardio_2_0) ? (
            <HeartRhythmItem2
              patient={patient}
              pr={cardio.ecg.pr}
              qrs={cardio.ecg.qrs}
              qtc={cardio.ecg.qtc}
            />
          ) : (
            <HeartRythmItem ecgDisc={risk.ecgDiscrepancy} />
          )}
        </button>

        <button
          type="button"
          onClick={() => {
            ui.set({ layer: Layer.Cholesterol });
          }}
        >
          <BloodFatsItem
            patient={patient}
            bloodfats={bloodwork}
            score2={score2}
          />
        </button>
      </SummarySet>

      <div>{bloodwork.crp && <CRPRange metrics={bloodwork.crp} />}</div>
    </div>
  );
}
