import { useState } from "react";
import { PopupDialog } from "render/ui/layouts/PopupDialog";
import { Typography } from "render/ui/presentation/Typography";
import { HoverTextButton } from "render/ui/trigger/HoverTextButton";
import ThumbsDn from "./assets/thumbsdown.svg?react";
import ThumbsUp from "./assets/thumbsup.svg?react";
import styles from "./styles.module.sass";
interface FeedbackModalProps {
  content: string;
  onOK({ feedback, isLiked }: { feedback: string; isLiked: boolean }): void;
  onCancel(): void;
}

export function FeedbackModal({ content, onOK, onCancel }: FeedbackModalProps) {
  const [feedback, setFeedback] = useState("");
  const [isLiked, setIsLiked] = useState<boolean>();

  const placeholderText = isLiked
    ? "Anything to add? (Optional)..."
    : "Describe the issue (optional)...";

  return (
    <>
      <PopupDialog>
        <div className={styles.container}>
          <Typography variant="title-m">Summary sent</Typography>
          <div className={styles.card}>
            <div className={styles.content}>
              <label>What did you think about the generated content?</label>
              <div className={styles.buttons}>
                <HoverTextButton
                  active={isLiked}
                  onClick={() => setIsLiked(true)}
                >
                  <ThumbsUp />
                </HoverTextButton>
                <HoverTextButton
                  active={isLiked === false}
                  onClick={() => setIsLiked(false)}
                >
                  <ThumbsDn />
                </HoverTextButton>
              </div>
            </div>
            {isLiked !== undefined && (
              <input
                type="text"
                placeholder={placeholderText}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setFeedback(e.target.value)
                }
              />
            )}
          </div>
          <div className={styles.footer}>
            <HoverTextButton
              type="button"
              onClick={() => {
                if (isLiked !== undefined) {
                  onOK({ feedback, isLiked });
                }
              }}
              disabled={isLiked === undefined}
            >
              Submit
            </HoverTextButton>
          </div>
        </div>
      </PopupDialog>
    </>
  );
}
