import { MouseEvent, ReactNode } from "react";
import { ArrowIcon } from "render/ui/symbol/ArrowIcon";
import classes from "./styles.module.sass";

interface BackButtonProps {
  onClick: (event: MouseEvent) => void;
  children: ReactNode;
}

export function BackButtonClick({ children, ...props }: BackButtonProps) {
  return (
    <div className={classes.BackButtonClick} {...props}>
      <ArrowIcon /> {children}
    </div>
  );
}
