import { cutSignal, Plot } from "lib/api/cardio";
import { clamp } from "lib/math";
import { useMemo } from "react";

export interface SliceOptions {
  heartbeats: number;
  bufferStartSec: number;
  bufferEndSec: number;
}

export interface Slice {
  slice: Plot | undefined;
  sliceStartSec: number;
  sliceEndSec: number;
}

export function useECGPlotSlice(
  original: Plot | undefined,
  { heartbeats, bufferStartSec, bufferEndSec }: SliceOptions
): Slice {
  return useMemo(() => {
    const DEFAULT_RETURN: Slice = {
      slice: original,
      sliceStartSec: NaN,
      sliceEndSec: NaN,
    };

    if (!original) {
      return DEFAULT_RETURN;
    }

    const { sliceStartSec, sliceEndSec } = calculateEcgSliceStartEnd(original, {
      heartbeats,
      bufferStartSec,
      bufferEndSec,
    });

    if (sliceStartSec < 0 || sliceStartSec >= sliceEndSec) {
      console.error(
        "useECGPlotSlice: Invalid slice options, start time must be greater than 0 and less than end time",
        sliceStartSec,
        sliceEndSec
      );
      return DEFAULT_RETURN;
    }

    const originalLengthSec = original.signal_length_sec;
    const sliceLengthSec = sliceEndSec - sliceStartSec;
    const sliceStartIndex = Math.floor(
      (sliceStartSec / originalLengthSec) * original.signal.length
    );

    const sliceSignal = cutSignal(original, sliceStartSec, sliceEndSec);
    const sliceRPeakIndices =
      original.r_peak_indices
        ?.map((r) => r - sliceStartIndex)
        .filter((r) => r >= 0 && r < sliceSignal.length) ?? [];

    const slice: Plot = {
      signal: sliceSignal,
      signal_length_sec: sliceLengthSec,
      r_peak_indices: sliceRPeakIndices,
    };

    return {
      slice: slice,
      sliceStartSec,
      sliceEndSec,
    };
  }, [original, heartbeats, bufferStartSec, bufferEndSec]);
}

export function calculateEcgSliceStartEnd(
  plot: Plot,
  { heartbeats, bufferStartSec, bufferEndSec }: SliceOptions
): {
  sliceStartSec: number;
  sliceEndSec: number;
} {
  const { r_peak_indices = [] } = plot;

  const toTimestamp = (index: number) =>
    (index / plot.signal.length) * plot.signal_length_sec;

  const isTimestampTooCloseToSignalStart = (timestamp: number) =>
    timestamp - bufferStartSec < 0;

  const filteredRPeaks: Array<{
    index: number;
    timestamp: number;
  }> = r_peak_indices
    .filter((index) => !isTimestampTooCloseToSignalStart(toTimestamp(index)))
    .map((index) => ({
      index,
      timestamp: toTimestamp(index),
    }))
    .slice(0, heartbeats);

  if (filteredRPeaks.length < heartbeats) {
    return { sliceStartSec: 0, sliceEndSec: heartbeats };
  }

  const firstRpeakTimestamp = filteredRPeaks[0].timestamp;
  const lastRpeakTimestamp = filteredRPeaks[heartbeats - 1].timestamp;

  const sliceStartSec = clamp(
    firstRpeakTimestamp - bufferStartSec,
    0,
    plot.signal_length_sec
  );

  const sliceEndSec = clamp(
    lastRpeakTimestamp + bufferEndSec,
    0,
    plot.signal_length_sec
  );

  return {
    sliceStartSec,
    sliceEndSec,
  };
}
