import { ReactNode } from "react";
import styles from "./styles.module.sass";

interface ModalPageProps {
  children: ReactNode;
  show?: boolean;
}

/**
 * A modal page that covers everything except the sidebar
 */
export function ModalPage({ children, show = true }: ModalPageProps) {
  return (
    <div className={styles.ModalPage} data-show={show}>
      <div className={styles.modalContent}>{children}</div>
    </div>
  );
}
