import { useEffect, useState } from "react";

export function useAudioAmplitude(audioBuffer?: AudioBuffer) {
  const [signal, setSignal] = useState<number[]>();

  useEffect(() => {
    if (!audioBuffer) {
      return;
    }

    setSignal(Array.from(audioBuffer.getChannelData(0)));

    return () => {
      setSignal(undefined);
    };
  }, [audioBuffer]);

  return signal;
}
