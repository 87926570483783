import { DateTime } from "luxon";

interface FullDateProps {
  date: DateTime;
}

export function FullDateLong({ date }: FullDateProps) {
  return (
    <time dateTime={date.toISO()}>{date.toFormat("ccc d LLL yyyy HH:mm")}</time>
  );
}
