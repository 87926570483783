import { useCallback, useRef } from "react";
import {
  flattenText,
  getCaretPosition,
  getSelectedTextLength,
  insertTextAtCursor,
  setCaretPosition,
} from "./caretUtils";
import Paragraph from "./components/Paragraph/Paragraph";
import styles from "./styles.module.sass";

const BULLET_MIN_LENGTH = 5;

interface BulletEditorProps {
  placeholder: string;
  content: string[];
  onContentChange: (value: string[]) => void;
}

export function BulletEditor({
  placeholder,
  content,
  onContentChange,
}: BulletEditorProps) {
  const inputRefs = useRef<(HTMLDivElement | null)[]>([]);

  const handleChange = useCallback(
    (changedIndex: number, newValue: string) => {
      const updated = content.map((v, index) => {
        return index === changedIndex ? newValue : v;
      });
      onContentChange(updated);
    },
    [content, onContentChange]
  );

  const getFocusedIndex = useCallback(() => {
    const focusedElement = document.activeElement;
    return inputRefs.current.findIndex((ref) => ref === focusedElement);
  }, []);

  const bulletInput = useCallback(
    (
      index: number,
      totalItems: number,
      value: string,
      onChange: (value: string) => void
    ) => {
      const isPlaceholder = value.length === 0;

      function handleKeyDown(
        index: number,
        event: React.KeyboardEvent<HTMLDivElement>
      ) {
        const currentRef = inputRefs.current.at(index);

        if (currentRef && event.key === "Enter") {
          event.preventDefault();
          if (event.currentTarget.innerText.length < BULLET_MIN_LENGTH) {
            return;
          }
          const cp = getCaretPosition(currentRef);
          const left = event.currentTarget.innerText.slice(0, cp);
          const right = event.currentTarget.innerText.slice(cp);
          const newValue = [
            ...content.slice(0, index),
            left,
            right,
            ...content.slice(index + 1),
          ];
          onContentChange(newValue);
          setTimeout(() => {
            inputRefs.current.at(index + 1)?.focus();
          }, 10);
        } else if (
          event.key === "Backspace" &&
          currentRef &&
          getCaretPosition(currentRef) === 0 &&
          index > 0 // do not allow deleting the first bullet
        ) {
          // delete bullet if standing at the beginning of a line and backspacing
          if (getSelectedTextLength(currentRef) > 0) {
            // if something is selected, the intent is to delete it!
            return;
          }
          event.preventDefault();
          const line = event.currentTarget.innerText;
          const newPos = content[index - 1].length;
          const newValue = [
            ...content.slice(0, index - 1),
            content[index - 1] + line, // append the current line to the previous bullet
            ...content.slice(index + 1),
          ];
          onContentChange(newValue);
          setTimeout(() => {
            // set the caret (potentially in the middle..) on the prev row
            const prev = inputRefs.current.at(index - 1);
            if (prev) {
              prev.focus();
              setCaretPosition(prev, newPos);
            }
          }, 10);
        } else if (event.key === "ArrowUp" && index > 0) {
          // Move focus to the previous line?
          const prevRef = inputRefs.current.at(index - 1);
          if (currentRef && prevRef) {
            const currentPosition = getCaretPosition(currentRef);
            if (currentPosition === 0) {
              event.preventDefault();
              prevRef.focus();
            }
          }
        } else if (event.key === "ArrowDown" && index < content.length - 1) {
          // Move focus to the next line?
          const nextRef = inputRefs.current.at(index + 1);
          if (currentRef && nextRef) {
            const currentPosition = getCaretPosition(currentRef);
            if (currentPosition === event.currentTarget.innerText.length) {
              event.preventDefault();
              nextRef.focus();
            }
          }
        }
      }

      const handlePaste = (event: React.ClipboardEvent<HTMLDivElement>) => {
        event.preventDefault();
        const text = event.clipboardData.getData("text");
        const sanitizedText = flattenText(text);
        insertTextAtCursor(flattenText(sanitizedText));
      };

      return (
        <div
          className={styles.editable}
          data-isplaceholder={isPlaceholder}
          ref={(el) => {
            if (el) {
              inputRefs.current[index] = el;
              if (el.innerText !== value) {
                el.innerText = value;
              }
              if (isPlaceholder && index === 0 && index !== getFocusedIndex()) {
                el.innerText = placeholder;
              }
            }
          }}
          onFocus={(e) => {
            if (e.currentTarget.textContent === placeholder) {
              e.currentTarget.textContent = "";
            }
          }}
          onBlur={(e) => {
            if (
              index === 0 &&
              totalItems === 1 &&
              e.currentTarget.textContent === "" &&
              index !== getFocusedIndex()
            ) {
              e.currentTarget.textContent = placeholder;
            }
          }}
          onPaste={handlePaste}
          contentEditable
          suppressContentEditableWarning
          onInput={(e) => {
            const div = e.currentTarget;
            onChange(div.innerText);
          }}
          onKeyDown={(e) => handleKeyDown(index, e)}
        />
      );
    },
    [content, onContentChange, getFocusedIndex, placeholder]
  );

  return (
    <div className={styles.BulletEditor}>
      <div className={styles.content}>
        {content.map((s, index) => {
          return (
            <Paragraph isBullet={true} key={index}>
              {bulletInput(index, content.length, s, (v) =>
                handleChange(index, v)
              )}
            </Paragraph>
          );
        })}
      </div>
    </div>
  );
}
