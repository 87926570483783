import { codecs, createPath } from "@pomle/paths";
import { createPaths as patient } from "./patient";

const root = createPath("/", {});

const internalEntry = root.append("/internal/entry/device-ui", {});
const notifications = root.append("/notifications", {});
const settings = root.append("/settings", {});
const scheduleSiteSelect = root.append("/schedule", {});
const schedule = root.append("/schedule/:siteId", { siteId: codecs.string });
const linda = root.append("/linda", {});
const skinCRM = root.append("/skin-crm", {});
const dashboardStyleGuide = root.append("/dashboard/style-guide", {});

export const paths = {
  root,
  internalEntry,
  notifications,
  settings,
  scheduleSiteSelect,
  schedule,
  linda,
  patient: patient(root),
  skinCRM,
  dashboardStyleGuide,
};
