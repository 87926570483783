import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { COMMAND_PRIORITY_HIGH, FORMAT_TEXT_COMMAND } from "lexical";
import { useEffect } from "react";

export function DisableFormattingPlugin() {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    return editor.registerCommand(
      FORMAT_TEXT_COMMAND,
      () => {
        return true; // disable all text formatting shortcuts
      },
      COMMAND_PRIORITY_HIGH
    );
  }, [editor]);

  return null;
}
