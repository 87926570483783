import { useCallback, useMemo, useState } from "react";

export function useHeartAudio() {
  const [muted, setMuted] = useState(true);
  const toggleMuted = useCallback(() => setMuted((prev) => !prev), []);

  return useMemo(
    () => ({ muted, setMuted, toggleMuted }),
    [muted, setMuted, toggleMuted]
  );
}
