import SeenIcon from "./Seen.svg?react";
interface SeenProps {
  seen: boolean | undefined;
}

export function Seen({ seen }: SeenProps) {
  if (seen == null) {
    return <div />;
  }
  if (seen) {
    return (
      <div>
        <SeenIcon /> Seen
      </div>
    );
  }
  return <div>Not Seen</div>;
}
