import { MetricName } from "@cur8/measurements";
import { fromAPI, VisitMeasurementSummary } from "@cur8/rich-entity";
import { silenceAbort } from "lib/error";
import { Metric, toMetric } from "lib/metric";
import { useEffect, useMemo, useState } from "react";
import { useAPIClient } from "render/context/APIContext";
import { useReporting } from "render/hooks/useReporting";

function getMetric<M extends MetricName>(
  name: M,
  visitMetrics: VisitMeasurementSummary[]
): Array<Metric<M>> {
  return visitMetrics.reduce<Metric<M>[]>((acc, visitMetric) => {
    const { measurements } = visitMetric;

    const result = measurements
      .filter((measurement) => {
        return measurement.metricName === name;
      })
      .sort((a, b) => {
        return (
          b.timestampStart.toUnixInteger() - a.timestampStart.toUnixInteger()
        );
      })
      .map((measurement) => toMetric(name, measurement))
      .at(0);

    if (result) {
      acc.push(result);
    }

    return acc;
  }, []);
}

export function usePatientVisitsMetrics(
  patientId: string | undefined,
  visitIds: string[] | undefined
) {
  const api = useAPIClient();
  const { logError } = useReporting();
  const [visitsMeasurements, setVisitsMeasurements] =
    useState<VisitMeasurementSummary[]>();

  useEffect(() => {
    if (patientId == null) {
      return;
    }
    if (visitIds == null || visitIds.length === 0) {
      return;
    }
    const requests = visitIds.map((visitId) =>
      api.measurement.fetchVisitMeasurements(patientId, visitId)
    );

    Promise.all(requests.map((req) => req.result))
      .then((result) => result.map(fromAPI.toVisitMeasurementSummary))
      .then(setVisitsMeasurements)
      .catch(silenceAbort)
      .catch(logError);

    return () => {
      requests.forEach((req) => req.abandon());
    };
  }, [api, logError, patientId, visitIds]);

  const result = useMemo(() => {
    const get = <M extends MetricName>(name: M) => {
      if (visitsMeasurements == null) {
        return undefined;
      }
      return getMetric(name, visitsMeasurements);
    };

    const body = {
      height: get("body.height"),
      bmi: get("body.bmi"),
      weight: get("body.weight"),
      waist: get("body.waist"),
      eyePressure: {
        left: get("risk_assessment.eye_pressure.left"),
        right: get("risk_assessment.eye_pressure.right"),
        wearsContactLenses: get(
          "risk_assessment.eye_pressure.wears_contact_lenses"
        ),
      },
      gripStrength: {
        left: get("body.grip_strength.left"),
        right: get("body.grip_strength.right"),
      },
    };

    const bloodwork = {
      cholesterol: get("bloodwork.cholesterol"),
      cholesterolHDL: get("bloodwork.cholesterol_hdl"),
      crp: get("bloodwork.crp"),
      hba1c: get("bloodwork.hba1c"),
      hdl: get("bloodwork.hdl"),
      ldl: get("bloodwork.ldl"),
      nonHDL: get("bloodwork.non_hdl"),
      triglycerides: get("bloodwork.triglycerides"),
      haemoglobin: get("bloodwork.haemoglobin"),
      glucose: get("bloodwork.glucose"),
      whiteBloodCells: get("bloodwork.white_blood_cells.total"),
      neutrophils: get("bloodwork.white_blood_cells.neutrophils"),
      basophils: get("bloodwork.white_blood_cells.basophils"),
      monocytes: get("bloodwork.white_blood_cells.monocytes"),
      eosinophils: get("bloodwork.white_blood_cells.eosinophils"),
      lymphocytes: get("bloodwork.white_blood_cells.lymphocytes"),
    };

    const cardio = {
      bloodOxygen: get("cardio.oxygen_saturation"),
      heartRate: get("cardio.heart_rate"),
      respiratoryRate: get("cardio.respiratory_rate"),
      anklePressure: {
        left: get("cardio.ankle_pressure.left"),
        right: get("cardio.ankle_pressure.right"),
      },
      brachialPressure: {
        left: get("cardio.brachial_pressure.left"),
        right: get("cardio.brachial_pressure.right"),
      },
      toePressure: {
        left: get("cardio.toe_pressure.left"),
        right: get("cardio.toe_pressure.right"),
      },
      ecg: {
        p: get("cardio.ecg.segments.p"),
        pr: get("cardio.ecg.segments.pr"),
        qrs: get("cardio.ecg.segments.qrs"),
        qt: get("cardio.ecg.segments.qt"),
        qtc: get("cardio.ecg.segments.qtc"),
      },
    };

    const risk = {
      congestiveHeartFailureHistory: get(
        "risk_assessment.congestive_heart_failure_history"
      ),
      ecgDiscrepancy: get("risk_assessment.ecg_discrepancy"),
      diabetesHistory: get("risk_assessment.diabetes_history"),
      drugInducedBleeding: get("risk_assessment.drug-induced_bleeding"),
      hypertensionHistory: get("risk_assessment.hypertension_history"),
      kidneyDisease: get("risk_assessment.kidney_disease"),
      labileINR: get("risk_assessment.labile_inr"),
      liverDisease: get("risk_assessment.liver_disease"),
      majorBleedingHistory: get("risk_assessment.major_bleeding_history"),
      strokeHistory: get("risk_assessment.stroke_history"),
      thromboembolismHistory: get("risk_assessment.thromboembolism_history"),
      TIAHistory: get("risk_assessment.tia_history"),
      vascularDiseaseHistory: get("risk_assessment.vascular_disease_history"),
    };

    return { bloodwork, body, cardio, risk };
  }, [visitsMeasurements]);

  return result;
}
