import { easeOutCubic } from "lib/ease";

export const HEART_ECG_OVERLAY_ANIMATION_CYCLE_MS = 3000;
export const HEART_ECG_OVERLAY_REVEAL_DELAY_MS = 4300;

export enum HeartEcgOverlayTimelineSteps {
  ArrowTop = 1,
  ArrowMid = 2,
  ArrowBottom = 3,
}

export const ArrowAnimationConfig = {
  keyframeAnimationOptions: {
    defaults: {
      duration: HEART_ECG_OVERLAY_ANIMATION_CYCLE_MS,
      easing: "linear",
      fill: "forwards",
      iterations: Infinity,
    },
    [HeartEcgOverlayTimelineSteps.ArrowTop]: () => ({
      ...ArrowAnimationConfig.keyframeAnimationOptions.defaults,
      delay: 0,
    }),
    [HeartEcgOverlayTimelineSteps.ArrowMid]: () => ({
      ...ArrowAnimationConfig.keyframeAnimationOptions.defaults,
      delay: 1000,
    }),
    [HeartEcgOverlayTimelineSteps.ArrowBottom]: () => ({
      ...ArrowAnimationConfig.keyframeAnimationOptions.defaults,
      delay: 2000,
    }),
  },
  keyframes: {
    line: ({
      lineDashStartOffset,
      lineDashMidOffset,
      lineDashEndOffset,
    }: {
      lineDashStartOffset: number;
      lineDashMidOffset: number;
      lineDashEndOffset: number;
    }) => [
      {
        offset: 0,
        strokeDashoffset: lineDashStartOffset,
        easing: ArrowAnimationConfig.easings.lineAnimateIn,
      },
      {
        offset: 750 / HEART_ECG_OVERLAY_ANIMATION_CYCLE_MS,
        strokeDashoffset: lineDashMidOffset,
        easing: ArrowAnimationConfig.easings.lineAnimateOut,
      },
      {
        offset: 1500 / HEART_ECG_OVERLAY_ANIMATION_CYCLE_MS,
        strokeDashoffset: lineDashEndOffset,
      },
      { offset: 1, strokeDashoffset: lineDashEndOffset },
    ],
    arrow: () => [
      {
        offset: 0,
        offsetDistance: "0%",
        transform: "scale(0)",
        easing: ArrowAnimationConfig.easings.lineAnimateIn,
      },
      {
        offset: 750 / HEART_ECG_OVERLAY_ANIMATION_CYCLE_MS,
        offsetDistance: "100%",
        transform: "scale(1)",
      },
      {
        offset: 1250 / HEART_ECG_OVERLAY_ANIMATION_CYCLE_MS,
        offsetDistance: "100%",
        transform: "scale(1)",
      },
      {
        offset: 1500 / HEART_ECG_OVERLAY_ANIMATION_CYCLE_MS,
        offsetDistance: "100%",
        transform: "scale(0)",
      },
      { offset: 1, offsetDistance: "100%", transform: "scale(0)" },
    ],
  },
  easings: {
    lineAnimateIn: "cubic-bezier(0.441, 0.9, 0.951, 0.98)",
    lineAnimateOut: "cubic-bezier(0.4, 0.18, 0.5, 0.95)",
  },
} as const;

export const getArrowAnimationConfig = (step: HeartEcgOverlayTimelineSteps) => {
  return ArrowAnimationConfig.keyframeAnimationOptions[step]();
};

export const RampUpNumberAnimationConfig = {
  duration: 1.7,
  ease: easeOutCubic,
} as const;
