import { APITypesV2 } from "@cur8/api-client";
import ReactMarkdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { dark } from "react-syntax-highlighter/dist/esm/styles/prism";
import styles from "./Bubble.module.sass";

interface BubbleProps {
  message: APITypesV2.OpenAIMessageV2;
}

export function Bubble({ message }: BubbleProps) {
  const content = message.content?.at(0)?.text ?? null;

  return (
    <div data-role={message.role} className={styles.Bubble}>
      <ReactMarkdown
        components={{
          code(props) {
            const { children, className, node, ...rest } = props;
            const match = /language-(\w+)/.exec(className || "");
            return match ? (
              <SyntaxHighlighter
                {...rest}
                children={String(children).replace(/\n$/, "")}
                style={dark}
                language={match[1]}
                PreTag="div"
                ref={null}
              />
            ) : (
              <code {...rest} className={className}>
                {children}
              </code>
            );
          },
          p({ node, ...props }) {
            return (
              <p
                {...props}
                style={{ marginTop: "1.5em", marginBottom: "1.5em" }}
              />
            );
          },
        }}
      >
        {content}
      </ReactMarkdown>
    </div>
  );
}
