import { ImmutableScan } from "@cur8/rich-entity";
import fallbackPCD from "lib/meshuga/neutral_pose.pcd";
import { createPoints, parsePointCloud } from "lib/meshuga/pointcloud";
import { useEffect, useState } from "react";
import { useAPIClient } from "render/context/APIContext";
import * as THREE from "three";

export type Pose = "front" | "back";

const SCALE: Record<Pose, number> = {
  front: 1,
  back: -1,
};

export function useSMPLPointCloud(
  pose: Pose,
  skinScan?: ImmutableScan | Error
) {
  const scan = useAPIClient().scan;

  const [result, setResult] = useState<AsyncResult<THREE.Points>>();

  useEffect(() => {
    if (skinScan && !(skinScan instanceof Error)) {
      return;
    }

    fetch(fallbackPCD)
      .then((r) => r.arrayBuffer())
      .then(parsePointCloud)
      .then(createPoints)
      .then((points) => {
        points.geometry.scale(1, SCALE[pose], 1);
        setResult({ data: points });
      })
      .catch((error) => {
        setResult({ error });
      });

    return () => {
      setResult(undefined);
    };
  }, [skinScan, pose]);

  useEffect(() => {
    if (!skinScan || skinScan instanceof Error) {
      return;
    }

    const request = scan.fetchSMPLXPointCloud({
      patientId: skinScan.patientId,
      scanId: skinScan.id,
      scanVersion: skinScan.version,
      pose,
    });

    request.result
      .then(parsePointCloud)
      .then(createPoints)
      .then((points) => {
        setResult({ data: points });
      })
      .catch((error) => {
        setResult({ error });
      });

    return () => {
      request.abandon();
      setResult(undefined);
    };
  }, [scan, skinScan, pose]);

  return result;
}
