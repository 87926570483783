import { FLAGS, Flags } from "lib/flags";
import { noop } from "lib/noop";
import { BodyAreas } from "lib/smpl";
import { toCenter } from "lib/three";
import { useMemo } from "react";
import { HeartEcgOverlay } from "render/fragments/avatar/overlay/HeartEcgOverlay";
import {
  getArrowAnimationConfig,
  HEART_ECG_OVERLAY_REVEAL_DELAY_MS,
  HeartEcgOverlayTimelineSteps,
} from "render/fragments/avatar/overlay/HeartEcgOverlay/HeartEcgOverlay.animations";
import { HeartRateFloat } from "render/fragments/avatar/overlay/HeartRate";
import { ScreenProjector } from "render/hooks/three/useProject";
import { useScreenAnchorsForObjects } from "render/hooks/three/useScreenAnchors";
import { useDelay } from "render/hooks/useDelay";
import { useDashboardContext } from "render/pages/DashboardPage/context/DashboardContext";
import { usePatientData } from "render/pages/DashboardPage/context/PatientDataContext";
import { Layer } from "render/pages/DashboardPage/types";
import { Overlay } from "../../components/Overlay";
import { PerspectiveOverlay } from "../../components/PerspectiveOverlay";
import HeartImage from "./assets/heart.png";
import styles from "./styles.module.sass";

const ACTIVE = new Set([
  Layer.Heart,
  Layer.Cardio,
  Layer.Circulation,
  Layer.Cholesterol,
]);

interface HeartLayerProps {
  layer: Layer;
  areas: BodyAreas;
  projector: ScreenProjector;
}

export function HeartLayer({ layer, areas, projector }: HeartLayerProps) {
  const { ui, heartAudio } = useDashboardContext();

  const {
    metrics: { cardio },
  } = usePatientData();

  const positions = useMemo(() => {
    const heart = toCenter(areas.heart);
    heart.y = 0;

    const heartRate = heart.clone();
    heartRate.x -= 33;
    heartRate.z += 26;

    const respRate = heart.clone();
    respRate.x -= 35;
    respRate.z -= 30;

    const ecgSegmentP = heart.clone();
    ecgSegmentP.x -= 22;
    ecgSegmentP.z += 28;

    const ecgSegmentPR = heart.clone();
    ecgSegmentPR.x += 2.5;
    ecgSegmentPR.z -= 8;

    const ecgSegmentQRS = heart.clone();
    ecgSegmentQRS.x += 3;
    ecgSegmentQRS.z -= 48;

    const ecgSegmentQTC = heart.clone();
    ecgSegmentQTC.x -= 35;
    ecgSegmentQTC.z -= 25;

    return {
      heart,
      heartRate,
      respRate,
      ecgSegmentP,
      ecgSegmentPR,
      ecgSegmentQRS,
      ecgSegmentQTC,
    };
  }, [areas]);

  const active = ACTIVE.has(layer);

  const anchors = useScreenAnchorsForObjects(projector, positions, active);

  const IS_CARDIO_2_0 = FLAGS.has(Flags.Cardio_2_0);

  const showHeartOverlay = IS_CARDIO_2_0 && ui.layer === Layer.Heart;
  const animateHeartOverlay = useDelay({
    active: showHeartOverlay,
    delay: HEART_ECG_OVERLAY_REVEAL_DELAY_MS + 750,
  });

  const showEcgSegmentP = useDelay({
    active: ui.layer === Layer.Heart,
    delay: HEART_ECG_OVERLAY_REVEAL_DELAY_MS,
  });

  const showEcgSegmentPR = useDelay({
    active: ui.layer === Layer.Heart,
    delay:
      HEART_ECG_OVERLAY_REVEAL_DELAY_MS +
      getArrowAnimationConfig(HeartEcgOverlayTimelineSteps.ArrowMid).delay,
  });

  const showEcgSegmentQRS = useDelay({
    active: ui.layer === Layer.Heart,
    delay:
      HEART_ECG_OVERLAY_REVEAL_DELAY_MS +
      getArrowAnimationConfig(HeartEcgOverlayTimelineSteps.ArrowBottom).delay,
  });

  const showEcgSegmentQTC = useDelay({
    active: ui.layer === Layer.Heart,
    delay:
      HEART_ECG_OVERLAY_REVEAL_DELAY_MS +
      getArrowAnimationConfig(HeartEcgOverlayTimelineSteps.ArrowBottom).delay +
      800,
  });

  return (
    <div className={styles.HeartLayer} data-layer={ui.layer}>
      {IS_CARDIO_2_0 ? (
        <>
          <Overlay pos={anchors?.heart}>
            <div
              className={styles.ecgHeartOverlay}
              data-active={showHeartOverlay}
              onClick={showHeartOverlay ? heartAudio.toggleMuted : noop}
            >
              <HeartEcgOverlay animate={animateHeartOverlay} />
            </div>
          </Overlay>

          <Overlay pos={anchors?.ecgSegmentP}>
            <div className={styles.ecgFloatingUI} data-active={showEcgSegmentP}>
              <HeartEcgOverlay.EcgSegmentP
                active={showEcgSegmentP}
                p={cardio.ecg.p}
                width={200}
              />
            </div>
          </Overlay>

          <Overlay pos={anchors?.ecgSegmentPR}>
            <div
              className={styles.ecgFloatingUI}
              data-active={showEcgSegmentPR}
            >
              <HeartEcgOverlay.EcgSegmentPR
                active={showEcgSegmentPR}
                pr={cardio.ecg.pr}
                width={320}
              />
            </div>
          </Overlay>

          <Overlay pos={anchors?.ecgSegmentQRS}>
            <div
              className={styles.ecgFloatingUI}
              data-active={showEcgSegmentQRS}
            >
              <HeartEcgOverlay.EcgSegmentQRS
                active={showEcgSegmentQRS}
                qrs={cardio.ecg.qrs}
                width={230}
              />
            </div>
          </Overlay>

          <Overlay pos={anchors?.ecgSegmentQTC}>
            <div
              className={styles.ecgFloatingUI}
              data-active={showEcgSegmentQTC}
            >
              <HeartEcgOverlay.EcgSegmentQTC
                active={showEcgSegmentQTC}
                qtc={cardio.ecg.qtc}
                width={150}
              />
            </div>
          </Overlay>
        </>
      ) : (
        cardio.heartRate && (
          <Overlay pos={anchors?.heartRate}>
            <div className={styles.heartRate}>
              <HeartRateFloat heartRate={cardio.heartRate} width={200} />
            </div>
          </Overlay>
        )
      )}

      {anchors && (
        <PerspectiveOverlay perspective={350} pos={anchors.heart}>
          <div className={styles.heart}>
            <button
              onClick={() => {
                ui.set({ layer: Layer.Heart });
              }}
            >
              <img src={HeartImage} alt="Your heart" />
            </button>
          </div>
        </PerspectiveOverlay>
      )}
    </div>
  );
}
