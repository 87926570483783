import { ModalPage } from "render/ui/layouts/ModalPage";
import { FollowUpMessageComponent } from "./components/FollowUpMessageComponent";
import { MemberSummaryCreate } from "./components/MemberSummaryCreate";
import { MemberSummaryEdit } from "./components/MemberSummaryEdit";

export type VisitModalType =
  | "FollowUpMessage"
  | "SummaryCreate"
  | "SummaryEdit";

export interface VisitModalProps {
  patientId: string;
  notificationId?: string;
  onClose: () => void;
  type: VisitModalType;
  visitId: string;
}

export function Modal({
  patientId,
  notificationId,
  onClose,
  type,
  visitId,
}: VisitModalProps) {
  function modalComp() {
    switch (type) {
      case "FollowUpMessage":
        return (
          <FollowUpMessageComponent
            patientId={patientId}
            visitId={visitId}
            notificationId={notificationId}
            hide={onClose}
          />
        );
      case "SummaryCreate":
        return (
          <MemberSummaryCreate
            patientId={patientId}
            visitId={visitId}
            hide={onClose}
          />
        );
      case "SummaryEdit":
        return (
          <MemberSummaryEdit
            patientId={patientId}
            visitId={visitId}
            hide={onClose}
          />
        );
      default:
        return <h1>Unsupported modal</h1>;
    }
  }

  return <ModalPage>{modalComp()}</ModalPage>;
}
