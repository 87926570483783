import { useEffect, useRef } from "react";
import styles from "./styles.module.sass";

interface PlaintextSectionEditorProps {
  title: string;
  placeholder: string;
  content: string | undefined;
  onChange: (value: string) => void;
}

function setHeight(target: HTMLTextAreaElement) {
  target.style.height = "auto"; // Reset height to calculate actual height
  target.style.height = `${target.scrollHeight}px`; // Adjust height based on content
}

export function PlaintextSectionEditor({
  title,
  placeholder,
  content,
  onChange,
}: PlaintextSectionEditorProps) {
  const ref = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (ref.current) {
      setHeight(ref.current);
    }
  }, [content]);

  return (
    <div className={styles.PlaintextSectionEditor}>
      <h3 className={styles.title}>{title}</h3>
      <div className={styles.content}>
        <textarea
          ref={ref}
          placeholder={placeholder}
          value={content}
          onInput={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
            onChange(e.target.value)
          }
          required
        />
      </div>
    </div>
  );
}
