import { fromAPI, VisitHistorySummary } from "@cur8/rich-entity";
import { silenceAbort } from "lib/error";
import { useEffect, useState } from "react";
import { useAPIClient } from "render/context/APIContext";
import { useReporting } from "../useReporting";

export function useVisitHistory({
  patientId,
}: {
  patientId: string | undefined;
}) {
  const { logError } = useReporting();
  const [visitHistory, setVisitHistory] = useState<VisitHistorySummary>();
  const apiClient = useAPIClient();

  useEffect(() => {
    if (patientId == null) {
      return;
    }

    const req = apiClient.visit.fetchVisitHistory({
      patientId,
    });

    req.result
      .then(fromAPI.toVisitHistory)
      .then(setVisitHistory)
      .catch(logError)
      .catch(silenceAbort);
  }, [apiClient.visit, logError, patientId]);

  return visitHistory;
}
