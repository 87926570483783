import { codecs, Path } from "@pomle/paths";
import { createPaths as appointment } from "./appointment";
import { createPaths as recording } from "./recording";
import { createPaths as scans } from "./scans";
import { createPaths as visit } from "./visit";

export function createPaths<T extends {}>(origin: Path<T>) {
  const create = origin.append("/patient-create", {});
  const invite = origin.append("/patient-invite", {});

  const overview = origin.append("/patient", {});
  const detail = overview.append("/:patientId", {
    patientId: codecs.string,
  });

  const dashboard = detail.append("/dashboard", {});
  const encyclopedia = detail.append("/encyclopedia", {});

  const bloodworkEntry = detail.append("/bloodwork", {});
  const riskAssessmentEntry = detail.append("/risk-assessment", {});

  const info = detail.append("/patient-information", {});
  const edit = info.append("/edit", {});

  const sendSMS = detail.append("/send-sms", {});
  const lesions = detail.append("/lesions", {});

  const trackedMarkings = detail.append("/tracked-markings", {});

  const copilot = detail.append("/copilot", {});

  const assessment = detail.append("/assessment/:assessmentId/:versionId", {
    assessmentId: codecs.string,
    versionId: codecs.string,
  });

  return {
    invite,
    overview,
    detail,
    encyclopedia,
    dashboard,
    bloodworkEntry,
    riskAssessmentEntry,
    lesions,
    info,
    edit,
    create,
    sendSMS,
    assessment,
    visit: visit(detail),
    recording: recording(detail),
    scans: scans(detail),
    appointment: appointment(detail),
    trackedMarkings,
    copilot,
  };
}
