interface ReviewMessageProps {
  isSent: boolean;
  remainingReviewTime: number;
  isInvalid: boolean;
}

export function ReviewMessage({
  isSent,
  remainingReviewTime,
  isInvalid,
}: ReviewMessageProps) {
  if (isSent) {
    return <label>Summary already sent</label>;
  }
  if (remainingReviewTime && isInvalid) {
    return (
      <label>
        Please review carefully and complete all sections ({remainingReviewTime}
        )
      </label>
    );
  }
  if (remainingReviewTime) {
    return (
      <label>Please edit and review carefully ({remainingReviewTime})</label>
    );
  }
  if (isInvalid) {
    return <label>Please complete all sections</label>;
  }
  return <label>You can now submit the summary if you are done editing</label>;
}
