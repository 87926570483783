import { useMemo } from "react";
import { Typography } from "render/ui/presentation/Typography";
import styles from "../styles.module.sass";

export type ECGGraphMarkerProps = {
  show?: boolean;
  type: "line" | "frame";
  x: number;
  y?: number;
  width?: number;
  label?: string;
  duration?: number;
};

export function ECGGraphMarker({
  type,
  x,
  width,
  label,
  show,
  duration,
}: ECGGraphMarkerProps) {
  const markerStyles = useMemo(() => {
    const styles: React.CSSProperties = {};

    styles.left = `${x}%`;

    if (width) {
      styles.width = `${width}%`;
    }

    if (duration) {
      styles["--ecg-marker-duration"] = `${duration}ms`;
      styles["--ecg-marker-ease"] = "linear";
    }

    return styles;
  }, [x, width, duration]);

  return (
    <div
      className={styles.marker}
      data-type={type}
      data-show={show}
      style={markerStyles}
    >
      <div className={styles.label}>
        <Typography variant="eyebrow-m">{label}</Typography>
      </div>
      {type === "line" ? (
        <div className={styles.line} />
      ) : (
        <div className={styles.frame} />
      )}
    </div>
  );
}
