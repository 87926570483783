import { useSites } from "render/hooks/api/useSites";
import { FramedPage } from "render/ui/layouts/FramedPage/FramedPage";
import { PageHeader } from "render/ui/layouts/PageHeader";
import { Calendar } from "./components/Calendar";
import { RoomDropDown } from "./components/RoomDropDown";
import { SchedulePageHeader } from "./components/SchedulePageHeader";
import { SchedulePageSubHeader } from "./components/SchedulePageSubHeader";
import { ScheduleContext } from "./context/ScheduleContext";
import styles from "./styles.module.sass";

interface SchedulePageProps {
  siteId: string;
}

export function SchedulePage({ siteId }: SchedulePageProps) {
  const sites = useSites();
  const name = sites?.find((site) => site.siteId === siteId)?.siteName ?? "--";

  return (
    <ScheduleContext siteId={siteId}>
      <FramedPage>
        <div className={styles.SchedulePage}>
          <div>
            <div className={styles.header}>
              <SchedulePageHeader />
              <PageHeader caption={name}>
                <RoomDropDown siteId={siteId} />
              </PageHeader>
            </div>
            <SchedulePageSubHeader />
          </div>
          <Calendar />
        </div>
      </FramedPage>
    </ScheduleContext>
  );
}
