import { EcgQRS } from "@cur8/health-risks-calc";
import { softCap } from "lib/health-risk";
import { Metric } from "lib/metric";
import { useMemo } from "react";
import { MetricRange } from "render/ui/presentation/MetricRange";
import {
  capToMaxRanges,
  toChartRanges,
} from "render/ui/presentation/RangeChart/lib/risk";

interface EcgQRSRangeProps {
  metrics: Metric<"cardio.ecg.segments.qrs">[] | undefined;
}

export function EcgQRSRange({ metrics }: EcgQRSRangeProps) {
  const riskRange = useMemo(() => {
    return EcgQRS.rangesFor();
  }, []);

  const entries = useMemo(() => {
    if (!metrics) {
      return [];
    }

    return metrics.map((metric) => {
      return {
        risk: riskRange.findRisk(metric.unit),
        timestamp: metric.measurement.timestampStart,
        value: metric.unit["ms"],
      };
    });
  }, [metrics, riskRange]);

  const currentValue = entries.at(0)?.value;

  const ranges = useMemo(() => {
    return capToMaxRanges(
      toChartRanges(riskRange.entries.map(softCap(0, 240))),
      currentValue
    );
  }, [currentValue, riskRange]);

  return (
    <MetricRange
      title="Ventricular contraction"
      subtitle="[QRS time]"
      unit="ms"
      entries={entries}
      ranges={ranges}
      formatValue={(value) => value?.toFixed(0)}
      showChange={false}
    />
  );
}
