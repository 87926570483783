import { fromAPI, ImmutableVisitSummary } from "@cur8/rich-entity";
import { useCallback, useEffect, useState } from "react";
import { useAPIClient } from "render/context/APIContext";
import { useReporting } from "render/hooks/useReporting";

export function useImmutableVisitSummaries(patientId: string) {
  const { logError } = useReporting();
  const api = useAPIClient();
  const [entries, setEntries] = useState<ImmutableVisitSummary[]>([]);

  const fetch = useCallback(() => {
    return api.immutableVisitSummary
      .querySummary({ patientId }, { isLatestVersion: true })
      .result.then((result) => {
        const visits = result.items.map(fromAPI.toImmutableVisitSummary);
        setEntries(visits);
      })
      .catch(logError);
  }, [api, setEntries, logError, patientId]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return {
    entries,
    fetch,
  };
}
