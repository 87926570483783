import { APITypesV1 } from "@cur8/api-client";
import { ImmutableVisitSummary, Visit } from "@cur8/rich-entity";
import { DateTime } from "luxon";
import { useMemo } from "react";
import { useSites } from "render/hooks/api/useSites";
import { VisitBox } from "./components/VisitBox";
import styles from "./styles.module.sass";

interface VisitsProps {
  visits?: Array<Visit>;
  visitSummaries: ImmutableVisitSummary[];
  refetch: () => void;
}

export function viewableStatus(status: APITypesV1.VisitStatus | undefined) {
  if (
    status === APITypesV1.VisitStatus.Cancelled ||
    status === APITypesV1.VisitStatus.NoShow ||
    status === APITypesV1.VisitStatus.Scheduled
  ) {
    return false;
  }
  return true;
}

export function Visits({ visits, visitSummaries, refetch }: VisitsProps) {
  const sites = useSites();

  const visitList = useMemo(() => {
    if (!visits) {
      return;
    }
    let oneOpen = false;
    // Set ONE visit as expanded, that is:
    //   1. Not too far in the future
    //   2. Has visible state
    //   3. Is the first to be expanded
    const now = DateTime.now().plus({ days: 1 }).endOf("day");
    return visits.map((v) => {
      const shouldOpen =
        !oneOpen && v.startTime <= now && viewableStatus(v.status);

      if (shouldOpen) {
        oneOpen = true;
      }
      return { ...v, expand: shouldOpen };
    });
  }, [visits]);

  return (
    <div className={styles.Visits}>
      {visitList ? (
        visitList.map((vis, idx) => {
          const vs = visitSummaries.filter(
            (sum) => sum.visitId === vis.visitId
          );
          const number = visitList.length - idx;
          return (
            <VisitBox
              key={idx}
              id={number}
              expanded={vis.expand}
              visit={vis}
              sites={sites}
              visitSummaries={vs}
              refetch={refetch}
            />
          );
        })
      ) : (
        <p>Loading visits...</p>
      )}
    </div>
  );
}
