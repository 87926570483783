import { EcgQTC } from "@cur8/health-risks-calc";
import { Patient } from "@cur8/rich-entity";
import { softCap } from "lib/health-risk";
import { Metric } from "lib/metric";
import { useMemo } from "react";
import { MetricRange } from "render/ui/presentation/MetricRange";
import {
  capToMaxRanges,
  toChartRanges,
} from "render/ui/presentation/RangeChart/lib/risk";

interface EcgQTCRangeProps {
  patient: Patient;
  metrics: Metric<"cardio.ecg.segments.qtc">[] | undefined;
}

export function EcgQTCRange({ patient, metrics }: EcgQTCRangeProps) {
  const riskRange = useMemo(() => {
    return EcgQTC.rangesFor({ sex: patient.sex });
  }, [patient]);

  const entries = useMemo(() => {
    if (!metrics) {
      return [];
    }

    return metrics.map((metric) => {
      return {
        risk: riskRange.findRisk(metric.unit),
        timestamp: metric.measurement.timestampStart,
        value: metric.unit["ms"],
      };
    });
  }, [metrics, riskRange]);

  const currentValue = entries.at(0)?.value;

  const ranges = useMemo(() => {
    return capToMaxRanges(
      toChartRanges(riskRange.entries.map(softCap(0, 900))),
      currentValue
    );
  }, [currentValue, riskRange]);

  return (
    <MetricRange
      title="Contract and reset time"
      subtitle="[QTc complex]"
      unit="ms"
      entries={entries}
      ranges={ranges}
      formatValue={(value) => value?.toFixed(0)}
      showChange={false}
    />
  );
}
