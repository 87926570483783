import { Flags, FLAGS } from "lib/flags";
import { useEffect } from "react";
import { useDashboardContext } from "render/pages/DashboardPage/context/DashboardContext";
import { usePatientData } from "render/pages/DashboardPage/context/PatientDataContext";
import { Typography } from "render/ui/presentation/Typography";
import { Layout, LayoutProps } from "../Layout";
import { SidePanel } from "../SidePanel";
import SoundOffIcon from "./assets/sound-off.svg?react";
import SoundOnIcon from "./assets/sound-on.svg?react";
import { HeartPanel } from "./components/HeartPanel";
import { HeartPanel as HeartPanel2 } from "./components/HeartPanel2";
import styles from "./styles.module.sass";

export function HeartLayout({ active }: LayoutProps) {
  const { patient } = usePatientData();
  const { heartAudio } = useDashboardContext();

  const IS_CARDIO_2_0 = FLAGS.has(Flags.Cardio_2_0);

  // mute heart audio when layout is inactive
  useEffect(() => {
    if (!active) {
      heartAudio.setMuted(true);
    }
  }, [heartAudio, active]);

  return (
    <Layout active={active}>
      <div className={styles.HeartLayout} data-active={active}>
        <div className={styles.heartPanel}>
          <SidePanel>
            {patient && (
              <>
                {IS_CARDIO_2_0 ? (
                  <HeartPanel2
                    active={active}
                    patient={patient}
                    muted={heartAudio.muted}
                  />
                ) : (
                  <HeartPanel active={active} patient={patient} />
                )}
              </>
            )}
          </SidePanel>
        </div>
        {IS_CARDIO_2_0 && (
          <button
            className={styles.soundButton}
            onClick={heartAudio.toggleMuted}
          >
            {heartAudio.muted ? <SoundOffIcon /> : <SoundOnIcon />}
            <Typography variant="label-m">Sound</Typography>
          </button>
        )}
      </div>
    </Layout>
  );
}
