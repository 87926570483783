import { APITypesV1 } from "@cur8/api-client";
import { ImmutableVisitSummary, Site, Visit } from "@cur8/rich-entity";
import { useNav } from "@pomle/react-router-paths";
import { useCallback, useMemo, useState } from "react";
import { useVisitAssessments } from "render/hooks/api/useVisitAssessments";
import { paths } from "render/routes/paths";
import { query } from "render/routes/querys";
import { FullDateLong } from "render/ui/format/FullDateLong";
import { Typography } from "render/ui/presentation/Typography";
import { Chevron } from "render/ui/symbol/Chevron";
import { PlusSign } from "render/ui/symbol/PlusSign";
import { HoverTextButton } from "render/ui/trigger/HoverTextButton";
import { viewableStatus } from "../../Visits";
import { Modal, VisitModalProps } from "./components/Modal";
import { AssessmentRow } from "./components/TableRow/AssessmentRow";
import FollowupMessageRow from "./components/TableRow/FollowupMessageRow";
import { VisitSummaryRow } from "./components/TableRow/VisitSummaryRow";
import styles from "./styles.module.sass";

interface VisitBoxProps {
  id: number;
  expanded?: boolean;
  sites?: Site[];
  visit: Visit;
  visitSummaries?: ImmutableVisitSummary[];
  refetch: () => void;
}

export function VisitBox({
  id,
  expanded,
  sites,
  visit,
  visitSummaries,
  refetch,
}: VisitBoxProps) {
  const [open, setOpen] = useState<boolean>(expanded || false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalProps, setModalProps] = useState<VisitModalProps>();
  const { assessments, unhandled } = useVisitAssessments(visit);
  const userFriendlyStatus = useCallback((status?: APITypesV1.VisitStatus) => {
    switch (status) {
      case APITypesV1.VisitStatus.Cancelled:
        return "Cancelled";
      case APITypesV1.VisitStatus.CheckedIn:
        return "Checked in";
      case APITypesV1.VisitStatus.Completed:
        return "Completed";
      case APITypesV1.VisitStatus.NoShow:
        return "No-show";
      case APITypesV1.VisitStatus.Ongoing:
        return "Ongoing";
      case APITypesV1.VisitStatus.Scheduled:
        return "Scheduled";
      default:
        return "N/A";
    }
  }, []);

  const nav = {
    assessment: useNav(paths.patient.assessment, query.assessment),
    create: useNav(paths.patient.visit.create),
    createFollowUpMessage: useNav(paths.patient.visit.followup),
  };

  const siteName =
    sites?.find((s) => s.siteId === visit.siteId)?.siteName ?? visit.siteId;

  const isExandable = useMemo(() => {
    return viewableStatus(visit.status);
  }, [visit.status]);

  const toggleOpen = useCallback(() => {
    if (!isExandable) {
      return;
    }
    setOpen((op) => !op);
  }, [isExandable]);

  const assessAll = useCallback(() => {
    if (!unhandled || unhandled.length <= 0) {
      return;
    }
    nav.assessment.go(
      {
        patientId: unhandled[0].patientId,
        assessmentId: unhandled[0].itemId,
        versionId: unhandled[0].itemVersion,
      },
      { unhandledQueue: [true] }
    );
  }, [unhandled, nav.assessment]);

  const onCloseModal = useCallback(() => {
    refetch();
    setShowModal(false);
    setModalProps(undefined);
  }, [refetch]);

  const openSummaryModal = useCallback(
    (patientId: string, visitId: string, type: "edit" | "create") => {
      setModalProps({
        patientId,
        onClose: onCloseModal,
        type: type === "create" ? "SummaryCreate" : "SummaryEdit",
        visitId,
      });
      setShowModal(true);
    },
    [onCloseModal]
  );
  const openFollowUpMsg = useCallback(
    (patientId: string, visitId: string, notificationId?: string) => {
      setModalProps({
        patientId,
        notificationId,
        onClose: onCloseModal,
        type: "FollowUpMessage",
        visitId,
      });
      setShowModal(true);
    },
    [onCloseModal]
  );

  return (
    <div className={styles.VisitBox} data-open={open}>
      <div
        className={styles.head}
        data-expandable={isExandable}
        onClick={toggleOpen}
      >
        <div className={styles.indexCol}>
          <div>{id}</div>
        </div>
        <div className={styles.centerCol}>
          <Typography variant="title-s">Body Scan</Typography>
          <span className={styles.dateAndSite}>
            <FullDateLong date={visit.startTime} /> at {siteName}
          </span>
        </div>
        <div className={styles.centerCol}>
          {open && unhandled && unhandled.length > 0 && (
            <HoverTextButton onClick={assessAll}>
              Assess all New
            </HoverTextButton>
          )}
        </div>
        <div className={styles.endCol}>
          <div className={styles.statusLabel} data-status={visit.status}>
            {userFriendlyStatus(visit.status)}
          </div>
          {isExandable && <Chevron direction={open ? "up" : "down"} />}
        </div>
      </div>
      {assessments && (
        <div className={styles.details}>
          <table className={styles.VisitTable}>
            <thead>
              <tr>
                <th></th>
                <th className={styles.name}>Name</th>
                <th>Status</th>
                <th>Assessed by</th>
                <th className={styles.date}>Last modified</th>
                <th>Medical Action</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {assessments.map((ass) => (
                <AssessmentRow key={ass.itemId} assessment={ass} />
              ))}
              <tr className={styles.VisitSummaryHeader}>
                <th colSpan={7}>
                  <Typography variant="title-s">Visit Summary</Typography>
                </th>
              </tr>
              {visitSummaries && visitSummaries.length > 0 && (
                <>
                  {visitSummaries.map((vs) => [
                    <VisitSummaryRow
                      key={vs.visitId}
                      patientId={visit.patientId}
                      openModal={openSummaryModal}
                      visitId={visit.visitId}
                      visitSummary={vs}
                    />,
                    vs.followUpMessages.map((m) => (
                      <FollowupMessageRow
                        key={m.notificationId}
                        patientId={visit.patientId}
                        message={m}
                        openModal={openFollowUpMsg}
                        visitId={visit.visitId}
                      />
                    )),
                  ])}
                  <tr className={styles.createSummary}>
                    <th colSpan={7}>
                      <button
                        className={styles.plusButton}
                        onClick={() =>
                          openFollowUpMsg(visit.patientId, visit.visitId)
                        }
                        type="button"
                      >
                        <PlusSign />
                        Add follow-up message
                      </button>
                    </th>
                  </tr>
                </>
              )}
              {!visitSummaries?.length && (
                <VisitSummaryRow
                  patientId={visit.patientId}
                  openModal={openSummaryModal}
                  visitId={visit.visitId}
                />
              )}
            </tbody>
          </table>
        </div>
      )}
      {showModal && modalProps && <Modal {...modalProps} />}
    </div>
  );
}
